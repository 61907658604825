import useTTRouter from "src/router/hooks/useTTRouter";
import { useAccount } from "src/providers/accountProvider";
import {
  ChartBarIcon,
  HomeIcon,
  PlusCircleIcon,
  UserGroupIcon,
  WalletIcon,
} from "@heroicons/react/24/solid";
import { useLocation } from "react-router-dom";
import { Sheet } from "react-modal-sheet";
import { useEffect, useState } from "react";

export function Footer() {
  const { account, balance } = useAccount();
  const {
    navigateToPartyCreation,
    navigateToHome,
    navigateToUserHistory,
    navigateToFollowing,
    navigateToOnboardMoney,
  } = useTTRouter();

  const location = useLocation();

  const [balanceOverview, setBalanceOverview] = useState(false);

  function openHistory() {
    if (account) {
      navigateToUserHistory(account?.id);
    }
  }

  const isActive = (path: string, exact = false) =>
    exact
      ? location.pathname === path
      : location.pathname.startsWith(path);

  const [activeStates, setActiveStates] = useState({
    isWalletActive: false,
    isHistoryActive: false,
    isPartyCreationActive: false,
    isFollowingActive: false,
    isHomeActive: false,
  });

  useEffect(() => {
    const updatedStates = {
      isWalletActive: isActive("/app/payment") || balanceOverview,
      isHistoryActive: isActive("/app/history"),
      isPartyCreationActive: isActive("/app/party/create"),
      isFollowingActive: isActive("/app/following"),
      isHomeActive: isActive("/app", true),
    };
    setActiveStates(updatedStates);
  }, [location.pathname, balanceOverview]);

  return (
    <>
      <Sheet
        detent="content-height"
        isOpen={balanceOverview}
        onClose={() => setBalanceOverview(false)}
      >
        <Sheet.Container className="h-2/5">
          <Sheet.Header className="bg-tic-taps-grey" />
          <Sheet.Content className="bg-tic-taps-grey pb-10 px-2">
            <p className="text-white font-semibold text-xl">Balance</p>
            <div className="items-center gap-y-2 h-full overflow-y-scroll">
              <div className="flex flex-row p-3 px-6 my-1 rounded-2xl bg-tic-taps-secondary-grey shadow-md w-full gap-x-2 items-center">
                <img src="icons/ttoken.webp" alt="" className="w-6" />
                <p className="text-white text-lg font-bold">
                  {balance.ticTapsTokens}
                </p>
              </div>
              <div className="flex flex-row p-3 px-6 my-1 rounded-2xl bg-tic-taps-secondary-grey shadow-md w-full gap-x-2 items-center">
                <img src="icons/coin.webp" alt="" className="w-6" />
                <p className="text-white text-lg font-bold">
                  {balance.dollars}
                </p>
              </div>
              <div className="flex flex-row p-3 px-6 my-1 rounded-2xl bg-tic-taps-secondary-grey shadow-md w-full gap-x-2 items-center">
                <img src="icons/trophy.webp" alt="" className="w-6" />
                <p className="text-white text-lg font-bold">
                  {balance.trophies}
                </p>
              </div>
            </div>
            <div className="w-full flex justify-center p-2">
              <button
                onClick={() => {
                  setBalanceOverview(false);
                  navigateToOnboardMoney();
                }}
                className="max-w-48 bg-white w-full rounded-lg px-1 py-2"
              >
                <p className="text-gray-900 text-lg font-semibold">Deposit</p>
              </button>
            </div>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop className="bg-tic-taps-grey" />
      </Sheet>

      <div className="bg-tic-taps-grey w-full pb-10 pt-4 border-t-hairline border-neutral-700 relative z-[50]">
        <div className="w-full md:w-2/3 lg:w-1/2 mx-auto flex justify-between items-center px-6">
          <button onClick={navigateToHome}>
            <HomeIcon
              className={`size-8 ${activeStates.isHomeActive ? "text-cyan-500" : "text-white hover:text-cyan-500"} transition-all duration-100 ease-in-out`}
            />
          </button>

          <button onClick={navigateToFollowing}>
            <UserGroupIcon
              className={`size-8 ${activeStates.isFollowingActive ? "text-cyan-500" : "text-white hover:text-cyan-500"} transition-all duration-100 ease-in-out`}
            />
          </button>

          <button onClick={navigateToPartyCreation}>
            <PlusCircleIcon
              className={`size-8 ${activeStates.isPartyCreationActive ? "text-cyan-500" : "text-white hover:text-cyan-500"} transition-all duration-100 ease-in-out`}
            />
          </button>

          <button onClick={openHistory}>
            <ChartBarIcon
              className={`size-8 ${activeStates.isHistoryActive ? "text-cyan-500" : "text-white hover:text-cyan-500"} transition-all duration-100 ease-in-out`}
            />
          </button>

          <button onClick={() => setBalanceOverview(true)}>
            <WalletIcon
              className={`size-8 ${activeStates.isWalletActive ? "text-cyan-500" : "text-white hover:text-cyan-500"} transition-all duration-100 ease-in-out`}
            />
          </button>
        </div>
      </div>
    </>
  );
}