import { Sheet } from "react-modal-sheet";
import { motion } from "framer-motion";
import GradientContainer from "./ui/GradientContainer";

interface EndGameRewardsSheetProps {
  open: boolean;
  background: string;
  onClose: () => void;
  totalTrophies: number | string;
}

const EndGameRewardsSheet: React.FC<EndGameRewardsSheetProps> = ({
  open,
  onClose,
  totalTrophies,
  background,
}) => {
  return (
    <Sheet
      isOpen={open}
      onClose={() => {
        onClose();
      }}
    >
      <Sheet.Container>
        <Sheet.Header className="text-white text-center font-bold text-2xl p-3 shadow-lg z-10">
          🎮 Thank you for playing! 🎮
        </Sheet.Header>

        <Sheet.Content className="relative h-screen flex flex-col">
          <div className="relative h-2/5 ">
            <img
              src={background}
              alt="Background"
              className="absolute inset-0 py-4 w-full h-full object-cover blur-md opacity-70"
            />
            <div className="absolute bottom-0 w-full h-1/2 bg-gradient-to-b from-transparent to-tic-taps-grey" />
            <div className="relative z-10 flex flex-col items-center justify-center h-full p-6">
              <h1 className="text-white text-2xl font-light text-center mb-4 py-1 bg-gradient-to-r from-transparent via-tic-taps-grey to-transparent bg-opacity-60 w-screen">
                🎁 Your Rewards 🎁
              </h1>
              <div className="flex items-center gap-4 justify-center">
                <motion.div
                  className="flex items-center gap-2 p-3 rounded-full"
                  initial={{ scale: 0.8 }}
                  animate={{ scale: 1 }}
                  transition={{ duration: 0.5, ease: "easeOut" }}
                >
                  <img
                    src="/icons/trophy.webp"
                    alt="Token Icon"
                    className="w-8 h-8"
                  />
                  <motion.span
                    className="text-2xl font-extrabold text-white"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.6, ease: "easeOut" }}
                  >
                    {totalTrophies}
                  </motion.span>
                </motion.div>
              </div>
            </div>
          </div>

          <div className="relative -mt-20 z-20 flex justify-center items-center bg-gradient-to-b from-transparent to-zinc-900">
            <div className="grid grid-cols-2 gap-8 w-11/12 max-w-3xl">
              <motion.div
                className="flex flex-col items-center bg-gradient-to-b pb-6 from-tic-taps-grey border-orange-500 border-hairline to-zinc-900 rounded-3xl shadow-2xl hover:shadow-lg transition-shadow duration-300"
                initial={{ scale: 0.9 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5, ease: "easeOut" }}
              >
                <span className="text-2xl font-semibold text-white mb-4 tracking-wide border-b-hairline border-orange-500 w-full text-center py-2 bg-zinc-900 rounded-t-3xl">
                  Trophies
                </span>
                <motion.div
                  className="w-16 h-16 p-2 mt-2 flex justify-center items-center rounded-full shadow-lg"
                  initial={{ y: -5 }}
                  animate={{ y: [0, -5, 0] }}
                  transition={{
                    duration: 1.5,
                    repeat: Infinity,
                    ease: "easeInOut",
                  }}
                >
                  <img
                    src="/icons/trophy.webp"
                    alt="Token Icon"
                    className="w-8 h-8"
                  />
                </motion.div>
                <span className="text-4xl font-bold text-orange-500 mt-6">
                  +{totalTrophies}
                </span>
              </motion.div>
            </div>
          </div>

          <div className="relative z-10 flex flex-col items-center justify-center flex-grow bg-zinc-900">
            <h1 className="text-white text-2xl font-light text-center mb-4 -mt-12 py-1 w-screen">
              Return to party to see your position 🥇
            </h1>
            <button
              className="relative w-full flex justify-center mt-12"
              onClick={onClose}
            >
              <GradientContainer styles="py-3.5 rounded-3xl w-3/4 text-center shadow-glow text-white font-bold text-xl mt-6">
                Back To Party
              </GradientContainer>
            </button>
          </div>
        </Sheet.Content>
        <Sheet.Backdrop className="z-[900] bg-black opacity-50 pointer-events-auto" />
      </Sheet.Container>
    </Sheet>
  );
};

export default EndGameRewardsSheet;
