import { useState } from "react";
import { GradientBorder } from "./ui/GradientContainer";
import { useNotifications } from "../providers/notificationProvider";
import usePartiesFunctions from "src/hooks/useParties";
import useTTRouter from "src/router/hooks/useTTRouter";

export function JoinPartyByCodeCard() {
  const [value, setValue] = useState<string | null>(null);
  const { logError } = useNotifications();
  const { joinPartyByCode } = usePartiesFunctions();
  const { navigateToParty } = useTTRouter();

  const [isLoading, setIsLoading] = useState(false);

  async function handleJoinByCode() {
    if (!value) {
      logError("Invalid code", "Please enter a code");
      return;
    }
    setIsLoading(true);
    try {
      const partyId = await joinPartyByCode(value);
      navigateToParty(partyId);
    } catch (err) {
      logError("Join Party", "Failed to join party");
    }
    setIsLoading(false);
  }

  return (
    <div className="relative w-full h-full">
      <div className="absolute w-full h-full backdrop-blur-xl rounded-xl"></div>
      <div className="relative bg-gradient-to-br to-white/10 via-[#34414d]/10 from-[#34414d]/10 border-hairline border-[#34414d]/30 rounded-xl p-3 space-y-2">
        <div>
          <p className="text-white font-semibold text-xl">Join a party! </p>
          <p className="text-white font-light text-sm">
            Enter the code to the party and join now!
          </p>
        </div>
        <div className="flex flex-row justify-between gap-x-2">
          <input
            className="flex-grow border-hairline border-[#34414d] rounded-xl px-2 h-10 text-sm bg-tic-taps-grey/50 text-white"
            placeholder="Enter code"
            autoCapitalize="none"
            autoComplete="off"
            autoCorrect="off"
            value={value ?? ""}
            onChange={(e) => setValue(e.target.value)}
          />
          <button disabled={isLoading} onClick={() => handleJoinByCode()}>
            <GradientBorder>
              <div className="bg-tic-taps-secondary-grey px-4 py-1 w-full">
                <p className="text-white font-semibold text-sm self-center">
                  Join
                </p>
              </div>
            </GradientBorder>
          </button>
        </div>
      </div>
    </div>
  );
}
