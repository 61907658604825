import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { ttRoutes } from "src/router/hooks/useTTRouter";
import { Header } from "src/components/layout/Header";
import { Footer } from "src/components/layout/Footer";

export const LayoutContext = createContext<{
  header: boolean;
  footer: boolean;
  secondaryHeader: boolean;
  secondaryHeaderText: string;

  showHeader: () => void;
  showSecondaryHeader: (text: string | undefined) => void;
  hideHeader: () => void;

  showFooter: () => void;
  hideFooter: () => void;
  setBackground: (setter: () => ReactNode) => void;
} | null>(null);

function shouldHaveHeader(path: string) {
  return [ttRoutes.home().toLowerCase()].includes(path.toLowerCase());
}
function shouldHaveFooter(path: string) {
  return (
    !path.toLowerCase().includes("game") &&
    !path.toLowerCase().includes("login")
  );
}

export const LayoutProvider = ({ children }: { children: React.ReactNode }) => {
  const [background, setBackground] = useState<ReactNode>(<></>);
  const [showHeader, setShowHeader] = useState(false);
  const [showFooter, setShowFooter] = useState(false);

  const [showSecondaryHeader, setShowSecondaryHeader] = useState(false);
  const [secondaryHeaderText, setSecondaryHeaderText] = useState<
    string | undefined
  >("");

  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    setShowHeader(shouldHaveHeader(pathname));
    setShowFooter(shouldHaveFooter(pathname));
  }, [pathname]);

  return (
    <LayoutContext.Provider
      value={{
        setBackground,
        header: showHeader,
        footer: showFooter,
        secondaryHeader: showSecondaryHeader,
        secondaryHeaderText: secondaryHeaderText ?? "",
        showFooter: () => {
          setShowFooter(true);
        },
        showHeader: () => {
          setShowHeader(true);
          setSecondaryHeaderText("");
          setShowSecondaryHeader(false);
        },
        showSecondaryHeader: (text: string | undefined) => {
          setShowSecondaryHeader(true);
          setSecondaryHeaderText(text);
          setShowHeader(false);
        },
        hideFooter: () => setShowFooter(false),
        hideHeader: () => {
          setShowHeader(false);
          setShowSecondaryHeader(false);
          setSecondaryHeaderText("");
        },
      }}
    >
      <div className="relative  h-screen w-full overflow-hidden">
        <div className="sticky top-0 z-10">
          <Header />
        </div>
        <div className="overflow-y-auto h-full">
          <div className="absolute top-0 right-0 w-full pointer-events-none z-0">
            {background}
          </div>
          <div className="w-full mb-4 z-20 pt-4">{children}</div>
        </div>
        <div className="sticky bottom-0 z-10 ">
          <Footer />
        </div>
      </div>
    </LayoutContext.Provider>
  );
};

export const useLayout = () => {
  const context = useContext(LayoutContext);

  if (!context) {
    throw new Error("useLayout must be used within a LayoutProvider");
  }

  return context;
};

//TODO: Add icons to the headers
export function useSecondaryHeader(text?: string) {
  const { showSecondaryHeader } = useLayout();
  useEffect(() => {
    showSecondaryHeader(text);
  }, [text]);
}

export function usePrimaryHeader() {
  const { showHeader } = useLayout();
  useEffect(() => {
    showHeader();
  }, []);
}

export function useHideHeader() {
  const { hideHeader } = useLayout();
  useEffect(() => {
    hideHeader();
  }, []);
}

export function useImageBackground(url: string, blurred = true) {
  const { setBackground } = useLayout();
  useEffect(() => {
    setBackground(() => (
      <img src={url} alt="" className="w-full h-full object-cover blur-lg" />
    ));
  }, [url]);
}

export function usePartyBackground(url: string, blurred = true) {
  const { setBackground } = useLayout();
  useEffect(() => {
    setBackground(() => (
      <div className="relative w-screen h-screen pt-20 flex justify-center">
        <img
          src={url}
          alt=""
          className={`w-full h-full object-cover blur-xl`}
        />
      </div>
    ));
  }, [url]);
}

export function useBubblesBackground() {
  const { setBackground } = useLayout();

  useEffect(() => {
    setBackground(() => (
      <img
        src={"/backgrounds/bubblesBackground.svg"}
        alt=""
        className="w-full h-full object-cover z-0"
      />
    ));
  }, []);
}

export function useNoBackground() {
  const { setBackground } = useLayout();

  useEffect(() => {
    setBackground(() => <></>);
  }, []);
}
