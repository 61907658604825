import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider } from "react-router-dom";
import router from "./router";
import {
  isTTError,
  NotificationProvider,
} from "./providers/notificationProvider";
import { AuthProvider } from "./providers/authProvider";
import { AccountProvider } from "./providers/accountProvider";
import { PartiesProvider } from "./providers/partiesProvider";
import { ChatProvider } from "./providers/chatProvider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient();

function Index() {
  queryClient.setDefaultOptions({
    queries: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      retry: false,
      throwOnError: (err: any) => {
        console.log(err)
        if (isTTError(err)) {
          if (err.code.includes("401") || err.code == "401") {
            localStorage.clear();
            window.location.reload();
            return false;
          }
        } else {
          console.error(err);
        }
        return true;
      },
    },
  });
  return (
    <QueryClientProvider client={queryClient}>
      <AccountProvider>
        <NotificationProvider>
          <PartiesProvider>
            <ChatProvider>
              <RouterProvider router={router} />
            </ChatProvider>
          </PartiesProvider>
        </NotificationProvider>
      </AccountProvider>
    </QueryClientProvider>
  );
}

root.render(
  <React.StrictMode>
    <AuthProvider>
      <NotificationProvider>
        <Index />
      </NotificationProvider>
    </AuthProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
