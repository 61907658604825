import { Navigate } from "react-router-dom";
import { useAuth } from "../../providers/authProvider";
import { ttRoutes } from "../hooks/useTTRouter";

export default function UnProtectedRoute({
  children,
}: {
  children: JSX.Element;
}): JSX.Element {
  const { isLoggedIn, token } = useAuth();
  if (isLoggedIn) {
    // Redirect unauthenticated users to the login page
    return <Navigate to={ttRoutes.home()} replace />;
  }

  // Render the protected component
  return children;
}
