export default function BlurredCircleBackground({
  color,
  gradientName,
  gradient,
}: {
  gradientName: string;
  color?: string;
  gradient?: string[];
}) {
  return (
    <div className="blur-2xl">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="85"
          cy="120"
          r="100"
          fill={`url(#${color || "gradient" + gradientName})`}
        />
        {!color && (
          <linearGradient
            id={"gradient" + gradientName}
            x1="0"
            y1="0"
            x2="0.75"
            y2="0.75"
          >
            {gradient?.map((color, index) => (
              <stop
                key={index}
                offset={`${index / (gradient.length - 1)}`}
                stopColor={color}
              />
            ))}
          </linearGradient>
        )}
      </svg>
    </div>
  );
}
