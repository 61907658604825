import {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
} from "react";
import Login from "../api/auth/login";
import { Magic } from "magic-sdk";
import GetAccount from "../api/account/profile/me";
import { useStorageState } from "../hooks/useStorageState";

//TODO: move this to env
const magic = new Magic("pk_live_FB762B59D0380117");

interface AuthContextProps {
  otpLogin: (
    email: string,
    onLoginSuccessfull: () => void,
    onLoginFailed: (message: string) => void
  ) => Promise<void>;
  logout: () => void;
  magic: any;

  isAuthLoading: boolean;
  isLoggedIn: boolean | undefined;
  token: string | undefined;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

export function AuthProvider({ children }: AuthProviderProps) {
  const [token, setToken] = useStorageState<string>(
    "token",
    "",
    (value) => value,
    (value) => value
  );

  const [isLoggedIn, setIsLoggedIn] = useStorageState<boolean>(
    "isLoggedIn",
    false,
    (value) => value.toString(),
    (value) => value === "true"
  );

  const [isAuthLoading, setIsAuthLoading] = useState<boolean>(false);

  async function initAuth() {
    setIsAuthLoading(true);
    try {
      await GetAccount();
    } catch (err) {
      clearToken();
    }
    setIsAuthLoading(false);
  }

  useEffect(() => {
    initAuth();
  }, []);

  function saveToken(token: string) {
    setToken(token);
  }

  function clearToken() {
    setToken(undefined);
    setIsLoggedIn(false);
  }

  async function otpLogin(
    email: string,
    onLoginSuccessfull: () => void,
    onLoginFailed: (message: string) => void
  ) {
    setIsAuthLoading(true);
    try {
      const token = await magic.auth.loginWithEmailOTP({
        email,
        showUI: true,
      });

      if (!token) {
        throw Error("Login failed");
      }

      await Login(token);
      saveToken(token);

      setIsLoggedIn(true);

      onLoginSuccessfull();
    } catch (err) {
      logout();
      onLoginFailed("Login Failed");
    }
    setIsAuthLoading(false);
  }

  function logout() {
    clearToken();
  }

  return (
    <AuthContext.Provider
      value={{
        otpLogin,
        logout,
        magic,
        isLoggedIn: isLoggedIn,
        token,
        isAuthLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}
