'use client'
import { AppScreen } from './AppScreen'
import main from '../staticComponents/images/mainpage.png'

export function AppDemo() {

  return (
    <AppScreen>
        <img src={main} alt="" />
    </AppScreen>
  )
}

