import { Patch } from 'src/api/fetch.wrapper';
import { routes } from 'src/api/routes';

interface UserData {
  nickname?: string;
  profile_image?: string;
  gamer_tag?: string;
}

const patchUserData = async (userId: string, data: UserData): Promise<void> => {
  try {
    const url = routes.user(userId);
    const response = await Patch(url, data);
    console.log('User updated successfully:', response);
  } catch (error) {
    console.error('Error updating user:', error);
  }
};

export default patchUserData;
