import { Switch } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { Sheet } from "react-modal-sheet";
import GradientContainer from "src/staticComponents/GradientContainer";

const SoundSheet = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const [isSoundOn, setIsSoundOn] = useState(true);

  useEffect(() => {
    const isMuted = document.querySelector("audio")?.muted;
    setIsSoundOn(isMuted !== true);
  }, []);

  const handleSave = () => {
    const audio = document.querySelector("audio");
    if (audio) {
      audio.muted = !isSoundOn;
    }

    onClose();
  };

  return  (
    <Sheet isOpen={open} onClose={onClose}>
      <Sheet.Container className="bg-custom-zinc w-1/3 max-w-full mx-auto max-h-[260px]">
        <Sheet.Header className="text-white text-center font-bold text-xl p-2 z-10 flex items-center justify-center relative">
          <span className="flex-1">Sound Settings</span>
          <ChevronDownIcon className="h-5 w-5 absolute right-2" />
        </Sheet.Header>

        <Sheet.Content className="relative flex flex-col items-center p-4 overflow-y-auto z-10 flex-grow">
          <div className="flex flex-col items-center flex-grow w-full pt-2">
            <div className="flex items-center justify-between w-full px-6 py-4 bg-tic-taps-grey border-hairline border-zinc-700 rounded-2xl">
              <p className="text-white">Sound</p>
              <Switch
                checked={isSoundOn}
                onChange={setIsSoundOn}
                className={`${
                  isSoundOn ? "bg-gradient-to-r from-purple-500 via-blue-500 to-cyan-500" : "bg-gray-600"
                } relative inline-flex items-center h-6 rounded-full w-11`}
              >
                <span
                  className={`${
                    isSoundOn ? "translate-x-6" : "translate-x-1"
                  } inline-block w-4 h-4 transform bg-white rounded-full transition`}
                />
              </Switch>
            </div>
          </div>

          <div className="flex flex-col items-center mt-auto mb-6 w-full">
            <GradientContainer styles="rounded-2xl w-full text-center shadow-glow">
              <button
                onClick={handleSave}
                className="text-white hover:text-purple-200 font-bold py-3 px-6"
              >
                Save changes
              </button>
            </GradientContainer>
          </div>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>
  );
};

export default SoundSheet;