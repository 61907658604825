import { ReactComponent as BubblesBackground } from "src/assets/bubblesBackground.svg";
import GradientContainer from "src/components/ui/GradientContainer";
import SecondaryHeader from "src/components/SecondaryHeader";
import TutorialPicture from "src/assets/tutorial.jpg";
interface RuleProps {
  title: string;
  content: string;
}

interface RuleData {
  title: string;
  content: string;
}
const Rule: React.FC<RuleProps> = ({ title, content }) => {
  return (
    <div className="mb-6 w-4/5 md:w-3/4 lg:w-2/3 flex flex-col justify-center z-[30]">
      <h1 className="text-lg font-semibold mb-2 text-left tracking-wide">{title}</h1>
      <p className="text-sm font-light text-gray-300 text-justify">{content}</p>
    </div>
  );
};
export function TutorialPage() {

  const rules: RuleData[] = [
    {
      title: 'Rule 1',
      content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
      Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. 
      Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
      Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. 
      Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
      Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. 
      Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum.`,
    },
    {
      title: 'Rule 2',
      content: `Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum. 
      Praesent mauris. Fusce nec tellus sed augue semper porta. 
      Mauris massa. Vestibulum lacinia arcu eget nulla.`,
    },
    {
      title: 'Rule 3',
      content: `Class aptent taciti sociosqu ad litora torquent per conubia nostra, 
      per inceptos himenaeos. Curabitur sodales ligula in libero. 
      Sed dignissim lacinia nunc. Curabitur tortor. Pellentesque nibh.`,
    },
    {
      title: 'Rule 4',
      content: `Aenean quam. In scelerisque sem at dolor. Maecenas mattis. 
      Sed convallis tristique sem. Proin ut ligula vel nunc egestas porttitor. 
      Morbi lectus risus, iaculis vel, suscipit quis, luctus non, massa. Aenean quam. In scelerisque sem at dolor. Maecenas mattis. 
      Sed convallis tristique sem. Proin ut ligula vel nunc egestas porttitor. 
      Morbi lectus risus, iaculis vel, suscipit quis, luctus non, massa. Aenean quam. In scelerisque sem at dolor. Maecenas mattis. 
      Sed convallis tristique sem. Proin ut ligula vel nunc egestas porttitor. 
      Morbi lectus risus, iaculis vel, suscipit quis, luctus non, massa.`,
    },
  ];

  return (
    <div className="relative flex flex-col items-center justify-between min-h-screen bg-gray-900 text-white p-4">
    <div className="absolute top-0 left-1/2 transform -translate-x-1/2 h-full w-full scale-x-200 blur-3xl z-[0]">
      <BubblesBackground />
    </div>
    
    <SecondaryHeader text="Party Tutorial"  />

    <div className="w-4/5 md:w-3/4 lg:w-1/3 mb-6 mt-6">
      <img
        src={TutorialPicture}
        alt="Game Tutorial"
        className="w-full max-h-80 rounded-xl shadow-lg"
      />
    </div>
    <div className="flex flex-col items-center justify-center w-full max-w-3xl z-[30]">
        {rules.map((rule, index) => (
          <Rule key={index} title={rule.title} content={rule.content} />
        ))}
      </div>

    <div className="fixed bottom-12 left-1/2 transform -translate-x-1/2 w-3/4 lg:w-1/2 max-w-2xl z-[30]">
      <GradientContainer styles="rounded-2xl">
        <button
          onClick={() => {}}
          className="w-full tracking-wider text-white font-bold text-xl px-8 py-4 rounded-full disabled:opacity-50"
        >
          Continue
        </button>
      </GradientContainer>
    </div>
  </div>
  );
};