import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createContext, useContext, ReactNode, useState } from "react";
import { Participant, Party } from "../types";
import GetParty from "../api/party/getParty";
import { useAccount } from "./accountProvider";
import GetParticipants from "../api/party/participants/getParticipants";

import GetLeaderboard from "../api/party/leaderboard/getLeaderboard";
import JoinPartyById from "../api/party/controls/joinPartyById";
import LeavePartyById from "../api/party/controls/leavePartyById";
import { SaveHighscore } from "../api/party/leaderboard/saveHighscore";
import EndParty from "../api/party/controls/endParty";

const PartyContext = createContext<
  | {
      party: Party | undefined;
      isPartyQueryLoading: boolean;

      endPartyById: (party: Party) => void;
      joinPartyById: (party: Party) => void;
      leavePartyById: (party: Party) => void;

      participants: Participant[];
      isParticipantsLoading: boolean;
      refreshParticipants: () => void;

      isAccountParticipant: boolean;
      isAccountOwner: boolean;

      leaderBoard: Participant[];
      submitHighscore: ({ score }: { score: number }) => void;
      isLeaderboardQueryLoading: boolean;

      refreshParty: () => void;
      refreshLeaderboard: () => void;

      isJoinPartyLoading: boolean;
      isLeavePartyLoading: boolean;
      isSubmitHighscoreLoading: boolean;
      isEndPartyLoading: boolean;
    }
  | undefined
>(undefined);
export function PartyProvider({
  children,
  partyId,
}: Readonly<{
  children: ReactNode;
  partyId: string;
}>) {
  const qClient = useQueryClient();
  const { account } = useAccount();
  const [isMutating, setIsMutating] = useState(false);
  const key = ["party", partyId];

  const partyQuery = useQuery({
    queryKey: key,
    queryFn: async () => await GetParty(partyId),
  });

  const participantsQuery = useQuery({
    queryKey: ["participants", partyId],
    queryFn: async () => await GetParticipants(partyId),
  });

  const leaderboardQuery = useQuery({
    queryKey: ["highscore", partyId],
    queryFn: async () => {
      return await GetLeaderboard(partyId);
    },
  });

  const joinPartyByIdMutation = useMutation({
    mutationFn: async (party: Party) => {
      setIsMutating(true);
      await JoinPartyById(
        party.id,
        party.inviteCode,
        party.buyInAmount,
        party.currencyType,
        party.currencyCode
      );
      qClient.invalidateQueries({ queryKey: ["participants", party.id] });
    },
    onError: () => setIsMutating(false),
    onSettled: () => setIsMutating(false),
  });

  const leavePartyByIdMutation = useMutation({
    mutationFn: async (party: Party) => {
      setIsMutating(true);
      await LeavePartyById(party.id);
      qClient.invalidateQueries({ queryKey: ["participants", party.id] });
    },
    onError: () => setIsMutating(false),
    onSettled: () => setIsMutating(false),
  });

  const submitHighscoreMutation = useMutation({
    mutationFn: async ({ score }: { score: number }) => {
      const partyId = partyQuery.data?.id;
      const data = await SaveHighscore(partyId!, score, score);
      qClient.invalidateQueries({
        queryKey: ["highscore", partyQuery.data?.id ?? ""],
      });
      return data;
    },
  });

  const endPartyMutation = useMutation({
    mutationFn: async (party: Party) => {
      const data = await EndParty(party.id);
      qClient.invalidateQueries({
        queryKey: key,
      });
      return data;
    },
  });

  function refreshParty() {
    qClient.refetchQueries({
      queryKey: key,
    });
  }

  const isJoinPartyLoading = joinPartyByIdMutation.status === "pending";
  const isLeavePartyLoading = leavePartyByIdMutation.status === "pending";
  const isSubmitHighscoreLoading = submitHighscoreMutation.status === "pending";
  const isEndPartyLoading = endPartyMutation.status === "pending";
  const isParticipantsLoading =
    participantsQuery.isLoading || participantsQuery.isFetching;

  const isAccountParticipant =
    participantsQuery.data?.find((p: any) => p.id == account?.id) !== undefined;

  const isAccountOwner = partyQuery.data?.createdById === account?.id;

  return (
    <PartyContext.Provider
      value={{
        leaderBoard: leaderboardQuery.data ?? [],
        submitHighscore: submitHighscoreMutation.mutate,
        party: partyQuery.data,
        isPartyQueryLoading: partyQuery.isLoading || partyQuery.isFetching,
        isLeaderboardQueryLoading: leaderboardQuery.isLoading,
        isAccountParticipant,
        isAccountOwner,
        endPartyById: endPartyMutation.mutate,
        joinPartyById: joinPartyByIdMutation.mutate,
        leavePartyById: leavePartyByIdMutation.mutate,
        participants: participantsQuery.data ?? [],
        isParticipantsLoading,
        refreshParticipants: () => participantsQuery.refetch(),
        refreshParty,
        refreshLeaderboard: () =>
          qClient.refetchQueries({
            queryKey: ["highscore", partyId],
          }),
        isJoinPartyLoading: isJoinPartyLoading || isMutating,
        isLeavePartyLoading: isLeavePartyLoading || isMutating,
        isSubmitHighscoreLoading,
        isEndPartyLoading,
      }}
    >
      {children}
    </PartyContext.Provider>
  );
}

export function useLeaderboard() {
  const context = useContext(PartyContext);
  if (context === undefined) {
    throw new Error("useLeaderboard must be used within a PartyProvider");
  }
  return context;
}

// Hook to use the PartyContext
export function useParty() {
  const context = useContext(PartyContext);
  if (context === undefined) {
    throw new Error("useParty must be used within a PartyProvider");
  }
  return context;
}
