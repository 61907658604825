import { Sheet } from "react-modal-sheet";
import { Participant, Profile } from "../types";

export default function ParticipantsSheet({
  isOpen,
  onClose,
  participants,
  followers,
  isLoading,
  addParticipant,
  removeParticipant,
}: {
  isOpen: boolean;
  onClose: () => void;
  participants: Participant[];
  followers: Profile[];
  isLoading: boolean;
  addParticipant: (user: Profile) => void;
  removeParticipant: (user: Profile) => void;
}) {
  const participantsProfiles = participants
    .map((participant) => participant.profile)
    .map((profile) => ({
      user: profile,
      isInvited: true,
    }));

  const _followers = followers.map((profile) => ({
    user: profile,
    isInvited: false,
  }));

  const possibleParticipants = [...participantsProfiles, ..._followers];

  return (
    <Sheet isOpen={isOpen} onClose={onClose}>
      <Sheet.Container>
        <Sheet.Header className="bg-tic-taps-grey" />
        <Sheet.Content className="bg-tic-taps-grey">
          <div className="flex space-x-2 pt-2 w-full px-2 flex-col">
            {possibleParticipants.map(({ user, isInvited }, index) => (
              <div
                className="flex flex-row p-2 my-1 rounded-xl bg-tic-taps-secondary-grey shadow-sm"
                key={user.id}
              >
                <div className="flex flex-row w-2/3 gap-x-2">
                  <div className="relative w-14">
                    <img
                      className="w-14 h-14 rounded-xl"
                      src={user.profileImage}
                    />
                    <div className="bg-blue-400 rounded-md absolute -bottom-2 right-0 left-0 mx-2">
                      <p className="text-xs text-white font-bold text-center">
                        {user.xp}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col items-center justify-center">
                    <p className="font-bold text-white">{user.gamertag}</p>
                    <p className="font-bold text-white text-xs">
                      Trophies: {user.xp}
                    </p>
                  </div>
                </div>
                <div className="flex flex-row justify-end items-center w-1/3">
                  {isInvited ? (
                    <div
                      onClick={() => removeParticipant(user)}
                      className=" h-12 flex items-center justify-center rounded-2xl w-24 border-hairline border-gray-500"
                    >
                      <p className="text-gray-500 font-light rounded text-center">
                        Kick
                      </p>
                    </div>
                  ) : (
                    <div
                      onClick={() => addParticipant(user)}
                      className="border-hairline border-gray-500 h-12 flex items-center justify-center rounded-2xl w-24"
                    >
                      <p className="text-gray-500 font-light rounded text-center">
                        Invite
                      </p>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop className="bg-tic-taps-grey" />
    </Sheet>
  );
}
