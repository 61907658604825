import React, { useEffect } from 'react';
import { ring } from 'ldrs';

ring.register();

const LoadingRing: React.FC<{ size?: number; stroke?: number; color?: string; speed?: number }> = ({
  size = 30,
  stroke = 4,
  color = 'cyan',
  speed = 2,
}) => {
  useEffect(() => {
  }, []);

  return (
    <l-ring
      size={size.toString()}
      stroke={stroke.toString()}
      bg-opacity="0"
      speed={speed.toString()}
      color={color}
    ></l-ring>
  );
};

export default LoadingRing;