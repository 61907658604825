import { Capacitor } from "@capacitor/core";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ttRoutes } from "../router/hooks/useTTRouter";

export function PlatformDecide() {
  const navigate = useNavigate();
  useEffect(() => {
    const platform = Capacitor.getPlatform();
    if (platform === "web") navigate("/static");
    else navigate(ttRoutes.login());
  }, []);
  return <></>;
}
