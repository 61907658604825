export default function SeeAll({ onClick }: { onClick: () => void }) {
  return (
    <p
      onClick={()=>onClick()}
      className="text-sm font-semibold text-transparent bg-clip-text text-white"
    >
      See all
    </p>
  );
}
