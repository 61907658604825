import { Post } from "../../fetch.wrapper";
import { routes } from "../../routes";

export default async function CreatePayment(
  amount: Number,
  currency_code: string
): Promise<string> {
  const data = await Post(routes.createPayment(), {
    amount,
    currency_code,
  });

  return data.checkout_url;
}
