import { Party } from "../types";
import { CreatePartySettings } from "src/pages/app/Party/CreatePartyPage";
import CreateParty from "../api/party/createParty";
import JoinPartyByCode from "../api/party/controls/joinPartyByCode";
import JoinPartyById from "../api/party/controls/joinPartyById";
import EndParty from "../api/party/controls/endParty";
import { useMutation } from "@tanstack/react-query";

export default function usePartiesFunctions() {
  const joinPartyByCodeMutation = useMutation({
    mutationFn: async (code: string) => {
      return await JoinPartyByCode(code);
    },
  });

  const joinPartyByIdMutation = useMutation({
    mutationFn: async (party: Party) => {
      return await JoinPartyById(
        party.id,
        party.inviteCode,
        party.buyInAmount,
        party.currencyType,
        party.currencyCode
      );
    },
  });

  const endPartyByIdMutation = useMutation({
    mutationFn: async (party: Party) => {
      return await EndParty(party.id);
    },
  });

  const createPartyMutation = useMutation({
    mutationFn: async (partySettings: CreatePartySettings) => {
      return await CreateParty(partySettings);
    },
  });

  return {
    joinPartyByCode: (code: string) =>
      joinPartyByCodeMutation.mutateAsync(code),
    joinPartyById: (party: Party) => joinPartyByIdMutation.mutateAsync(party),
    endPartyById: (party: Party) => endPartyByIdMutation.mutateAsync(party),
    createParty: (partySettings: CreatePartySettings) =>
      createPartyMutation.mutateAsync(partySettings),
  };
}
