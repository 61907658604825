import { Account } from "../../../types";
import { Get } from "../../fetch.wrapper";
import { routes } from "../../routes";

export default async function GetAccount(): Promise<Account> {
  const data = await Get(routes.me());
  return {
    birthDate: data.birth_date ? new Date(data.birth_date) : null,
    email: data.email,
    gamerTag: data.gamer_tag,
    id: data.user_id,
    nickName: data.nickname,
    profileImage: data.profile_image,
    chatToken: data.chat_token,
    country: {
      code: "Netherlands",
      name: "NL",
    },
    soundOn: true,
  };
}
