import { useQuery, useQueryClient } from "@tanstack/react-query";
import React, { createContext, useContext, ReactNode } from "react";
import { Party } from "../types";
import GetPublicParties from "../api/party/getPublicParties";
import GetPrivateParties from "../api/party/getPrivateParties";
import { useAccount } from "./accountProvider";

// Create a context with undefined initial value
const PartiesContext = createContext<
  | {
      isPartiesLoading: boolean;
      publicParties: Party[];
      privateParties: Party[];
      refreshParties: () => void;
    }
  | undefined
>(undefined);

// Create the provider component
export const PartiesProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { account } = useAccount();
  const qClient = useQueryClient();

  const publicPartiesQueryKey = ["publicPartiesKey", account?.id];

  const publicPartiesQuery = useQuery({
    queryKey: publicPartiesQueryKey,
    enabled: !!account,
    queryFn: async () => await GetPublicParties(),
  });

  const privatePartiesQueryKey = ["privateParties", account?.id];

  const privatePartiesQuery = useQuery({
    queryKey: privatePartiesQueryKey,
    enabled: !!account,
    queryFn: async () => await GetPrivateParties(),
  });

  function refreshParties() {
    qClient.refetchQueries({
      queryKey: publicPartiesQueryKey,
    });
    qClient.refetchQueries({
      queryKey: privatePartiesQueryKey,
    });
  }

  return (
    <PartiesContext.Provider
      value={{
        isPartiesLoading:
          publicPartiesQuery.isLoading || privatePartiesQuery.isLoading,
        privateParties: privatePartiesQuery.data ?? [],
        publicParties: publicPartiesQuery.data ?? [],
        refreshParties,
      }}
    >
      {children}
    </PartiesContext.Provider>
  );
};

export function useParties() {
  const context = useContext(PartiesContext);
  if (context === undefined) {
    throw new Error("useParties must be used within a PartiesProvider");
  }
  return context;
}
