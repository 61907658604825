import { Get } from "../../fetch.wrapper";
import { routes } from "../../routes";

export default async function GetIsFollowing(
  accountId: string,
  profileId: string
): Promise<boolean> {
  const data = await Get(routes.getFollowing(accountId));


  return false;
}
