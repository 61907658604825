import { TTError } from "../providers/notificationProvider";

export async function Get(url: string) {
  try {
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return handleResponse(response);
  } catch (err) {
    console.error(err);
    throw err;
  }
}

export async function Patch(url: string, data: any): Promise<any> {
  try {
    const token = localStorage.getItem("token");
    const headers: HeadersInit = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    const options: RequestInit = {
      method: 'PATCH',
      headers,
      body: JSON.stringify(data),
    };

    const response = await fetch(url, options);

    if (!response.ok) {
      const errorDetails = await response.json();
      throw new Error(
        `PATCH request failed. Status: ${response.status}, Details: ${errorDetails.message || 'Unknown error'}`
      );
    }

    return response.json();
  } catch (error) {
    console.error('Error in PATCH request:', error);
    throw error;
  }
}

export async function Post(url: string, body: any, specificToken?: string) {
  try {
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${specificToken ?? token}`,
      },
      body: JSON.stringify(body),
    });
    return handleResponse(response);
  } catch (err) {
    console.error(err);
    throw err;
  }
}

export async function Delete(url: string, body: any) {
  try {
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    });
    return handleResponse(response);
  } catch (err) {
    console.error(err);
    throw err;
  }
}

async function handleResponse(response: Response) {
  const data = await response.json();

  if (response.ok) {
    return data.body;
  }

  if (response.status == 401) throw TTError("401", "401", "401");

  const description = Object.keys(data).includes("message")
    ? data.message
    : undefined;

  const error_code = Object.keys(data).includes("error_code")
    ? data.error_code
    : undefined;

  if (description && error_code)
    throw TTError(error_code, "Error", description);

  else
    throw TTError(
      "Undocumented Error",
      "Server Error",
      `${response.status} ${response.statusText}`
    );
}
