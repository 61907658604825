import { Outlet } from "react-router-dom";
import { LayoutProvider } from "./providers/layoutProvider";
import { ChatProvider } from "./providers/chatProvider";
import { NotificationProvider } from "./providers/notificationProvider";
import { PartiesProvider } from "./providers/partiesProvider";

export default function App() {
  return (
    <ChatProvider>
      <LayoutProvider>
        <NotificationProvider>
          <PartiesProvider>
            <Outlet />
          </PartiesProvider>
        </NotificationProvider>
      </LayoutProvider>
    </ChatProvider>
  );
}
