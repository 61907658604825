
import MobileAppButtons from './MobileAppButtons'
import BubblesBackground from './BubblesBackground'
import { Container } from './Container'
import { Logo } from './Logo'
import NavLinks from './NavLinks';
import { InstallButton } from './Button';
import { GradientBorder } from './GradientContainer';


function QrCodeBorder(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg viewBox="0 0 96 96" fill="none" aria-hidden="true" {...props}>
      <path
        d="M1 17V9a8 8 0 0 1 8-8h8M95 17V9a8 8 0 0 0-8-8h-8M1 79v8a8 8 0 0 0 8 8h8M95 79v8a8 8 0 0 1-8 8h-8"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}

export function Footer() {
  return (
    <footer className="bg-tic-taps-grey z-[40] overflow-hidden">
      <Container>
        <div className="relative flex flex-col items-start justify-between gap-y-12 pb-6 pt-16 lg:flex-row lg:items-center lg:py-16">
        <div className="absolute left-1/2 top-20 transform -translate-x-1/2 w-full opacity-75 scale-x-100 blur-3xl z-0">
      <BubblesBackground />
      </div>

      <div className="flex items-center justify-between text-white w-full">
  <div className="flex items-center">
    <Logo />
    <div className="ml-4">
      <p className="text-base font-semibold hidden md:block">TicTaps</p>
    </div>
    <nav className="ml-8 md:ml-12 flex gap-8 mt-0">
      <NavLinks />
    </nav>
  </div>
  <div className="flex items-center space-x-4 z-40">
    <div className="block md:hidden">
     <InstallButton />
    </div>
    <div className="hidden md:block">
     <InstallButton />
    </div>
  </div>
</div>

        </div>
        <div className="flex items-center border-t border-gray-200 pt-8 justify-center md:pt-6">
           <p className="mt-2 pb-6 text-sm text-gray-200 text-center">
             &copy; Copyright {new Date().getFullYear()}. All rights reserved.
            </p>
              </div>

      </Container>
    </footer>
  )
}
