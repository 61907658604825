import { ChevronDownIcon } from "@heroicons/react/24/solid";
import React, { useState } from "react";
import { Sheet } from "react-modal-sheet";
import GradientContainer from "src/staticComponents/GradientContainer";

interface PrivacySheetProps {
  open: boolean;
  onClose: () => void;
}

const PrivacySheet: React.FC<PrivacySheetProps> = ({ open, onClose }) => {
  return (
    <Sheet isOpen={open} onClose={onClose}>
      <Sheet.Container className="bg-custom-zinc max-h-[500px]">
      <Sheet.Header className="text-white text-center font-bold text-xl p-2 z-10 flex items-center justify-center relative">
          <span className="flex-1">Privacy Policy</span>
          <ChevronDownIcon className="h-5 w-5 absolute right-2" />
        </Sheet.Header>

        <Sheet.Content className="relative flex flex-col items-center p-4 overflow-hidden z-10 flex-grow">
          <div className="flex flex-col items-center space-y-4 text-white">
            <p className="text-sm text-gray-400">
              This Privacy Policy describes how we collect, use, and share your
              personal information.
            </p>
            <p className="text-sm text-gray-400">
              We value your privacy and are committed to safeguarding your
              personal data. By using our services, you agree to the collection
              and use of information as described in this policy.
            </p>
            <p className="text-sm text-gray-400">
              We collect information when you register, use our services, or
              interact with our platform. This may include personal details like
              name, email, IP address, and device information.
            </p>
            <p className="text-sm text-gray-400">
              We do not share your personal information with third parties
              unless required by law or to provide services you request.
            </p>
            <p className="text-sm text-gray-400">
              If you have any questions or concerns about our privacy policy,
              please contact us.
            </p>
          </div>

          <div className="flex flex-col items-center mt-auto mb-6 w-full">
            <GradientContainer styles="rounded-2xl w-full text-center shadow-glow">
              <button
                onClick={onClose}
                className="text-white hover:text-purple-200 font-bold py-3 px-6"
              >
                Close
              </button>
            </GradientContainer>
          </div>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>
  );
};

export default PrivacySheet;
