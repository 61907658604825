import useTTRouter from "../router/hooks/useTTRouter";
import { Profile } from "../types";
import { LoadingSpinner } from "./LoadingOverlay";
import SeeAll from "./SeeAll";

export function FollowersList({
  isFollowersLoading,
  followers,
  title,
}: {
  isFollowersLoading: boolean;
  followers: Profile[];
  title: string;
}) {
  const { navigateToUserProfile, navigateToFollowers } = useTTRouter();

  function handleNavigation(follower: Profile) {
    navigateToUserProfile(follower.id);
  }

  if (isFollowersLoading) {
    return (
      <div className="w-full pt-4 gap-y-2">
        <div className="flex flex-row w-full justify-between px-4 ">
          <p className="text-white font-semibold text-sm">{title}</p>
        </div>
        <div className="gap-y-1 px-2 flex p-2 items center justify-center">
          <LoadingSpinner />
        </div>
      </div>
    );
  }

  return (
    <div className="w-full pt-4 space-y-2">
    <div className="flex flex-row w-full justify-between px-4">
    <p className="text-white font-semibold text-sm">{title}</p>
    <SeeAll onClick={()=>navigateToFollowers()} />
     </div>
  
     <div className="gap-y-1 px-2">
     <div className="flex overflow-x-auto space-x-2 pt-2 px-2 pb-2 scrollbar-hide">
    {followers.map((follower, index) => (
    <div key={index} className="flex-shrink-0 flex flex-col items-center w-20">
      <button
        onClick={() => handleNavigation(follower)}
        className="w-20 h-20 backdrop-blur-lg bg-tic-taps-grey/30 rounded-lg border border-[#F5F7FA0F] overflow-hidden"
      >
        <img
          src={follower.profileImage}
          alt="Follower Profile"
          className="w-full h-full object-cover rounded-lg"
        />
      </button>
      <p className="text-white text-xs font-semibold mt-1 text-center truncate w-full">
        {follower.gamertag}
      </p>
    </div>
    ))}
   </div>
  </div>

  {followers.length === 0 && (
    <p className="text-white text-center font-semibold text-sm">
      No followers yet 🥺
    </p>
  )}
    </div>
  );
}
