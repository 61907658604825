import {
  BellIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CogIcon,
  InformationCircleIcon,
  ShieldCheckIcon,
  UserIcon,
} from "@heroicons/react/24/solid";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { LayoutProvider } from "src/providers/layoutProvider";
import BubblesBackground from "src/staticComponents/BubblesBackground";
import GradientContainer from "src/staticComponents/GradientContainer";
import AccountSheet from "./AccountSheet";
import SoundSheet from "./SoundSheet";
import PrivacySheet from "./PrivacySheet";
import AboutUsSheet from "./AboutUsSheet";
import { ArrowRightStartOnRectangleIcon } from "@heroicons/react/24/outline";
import { LogOutModal } from "src/components/Modals";

const Settings = () => {
  const [activeSheet, setActiveSheet] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleBackClick = () => navigate(-1);
  const closeSheet = () => setActiveSheet(null);

  const settingsOptions = [
    {
      name: "Account",
      icon: UserIcon,
      onClick: () => setActiveSheet("Account"),
    },
    { name: "Sound", icon: BellIcon, onClick: () => setActiveSheet("Sound") },
    {
      name: "Privacy",
      icon: ShieldCheckIcon,
      onClick: () => setActiveSheet("Privacy"),
    },
    {
      name: "About Us",
      icon: InformationCircleIcon,
      onClick: () => setActiveSheet("About Us"),
    },
  ];

  const handleLogOut = () => {
    console.log("Logging out...");
    setIsModalOpen(false);
  };

  return (
    <LayoutProvider>
      <div className="absolute w-full h-full top-10 blur-3xl z-[1] opacity-90">
        <BubblesBackground />
      </div>
      <div className="relative flex flex-col items-center justify-center pt-2 pb-60 z-[20] w-full md:w-2/3 lg:w-1/2 mx-auto space-y-8">
        <div className="relative w-full flex items-center justify-between px-6 md:px-8">
          <button
            onClick={handleBackClick}
            className="bg-transparent relative z-[50] text-white rounded-full hover:bg-gray-600"
          >
            <ChevronLeftIcon className="h-8 w-8" />
          </button>
          <h1 className="text-white text-2xl font-light text-center flex-1 -ml-8">
            Settings
          </h1>
        </div>
        <GradientContainer styles="rounded-full shadow-glow">
          <CogIcon className="h-20 w-20 p-3 text-white" />
        </GradientContainer>
        <div className="w-4/5 space-y-3">
          {settingsOptions.map((option, index) => (
            <div
              key={index}
              onClick={option.onClick}
              className="bg-gradient-to-br from-[#383d44] to-zinc-900 rounded-2xl p-3 border border-[#2d3138] flex items-center justify-between cursor-pointer transition-transform transform hover:scale-105"
            >
              <div className="flex items-center space-x-4">
                <option.icon className="h-6 w-6 text-gray-100" />
                <p className="text-white font-light">{option.name}</p>
              </div>
              <ChevronRightIcon className="h-6 w-6 text-cyan-500" />
            </div>
          ))}
        </div>
        <p
          className="text-red-500 font-light text-center flex-1 cursor-pointer flex items-center justify-center"
          onClick={() => setIsModalOpen(true)}
        >
          <ArrowRightStartOnRectangleIcon className="text-red-500 h-6 w-6 mr-2" />
          Log Out
        </p>
      </div>

      <LogOutModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleLogOut}
      />

      {activeSheet === "Account" && (
        <AccountSheet open={true} onClose={closeSheet} />
      )}
      {activeSheet === "Sound" && (
        <SoundSheet open={true} onClose={closeSheet} />
      )}
      {activeSheet === "Privacy" && (
        <PrivacySheet open={true} onClose={closeSheet} />
      )}
      {activeSheet === "About Us" && (
        <AboutUsSheet open={true} onClose={closeSheet} />
      )}
    </LayoutProvider>
  );
};

export default Settings;
