import { useState } from "react";
import BubblesBackground from "src/staticComponents/BubblesBackground";
import { useAccount } from "src/providers/accountProvider";
import { useNoBackground } from "src/providers/layoutProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeartBroken, faSearch } from "@fortawesome/free-solid-svg-icons";
import { GradientBorder } from "src/components/ui/GradientContainer";
import { Profile } from "src/types";
import useTTRouter from "src/router/hooks/useTTRouter";
import useFollowing from "src/hooks/useFollowing";
import { UnfollowModal } from "src/components/Modals";
import { LoadingOverlay } from "src/components/LoadingOverlay";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";

export function FollowingPage() {
  const { unfollow } = useFollowing();
  useNoBackground();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedFollowing, setSelectedFollowing] = useState<Profile | null>(
    null
  );
  const [isUnfollowModal, setIsUnfollowModal] = useState(false);
  const { following, isFollowingLoading } = useAccount() || {};
  const { navigateToUserProfile, navigateBack } = useTTRouter();

  const filteredFollowing = following?.filter((user) =>
    user.gamertag.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleUnfollowConfirm = () => {
    if (selectedFollowing) {
      setIsUnfollowModal(false);
      unfollow(selectedFollowing.id).then(() => {
        window.location.reload();
      });
    }
  };

  const handleNavigation = (selectedFollowing: Profile) => {
    if (selectedFollowing) navigateToUserProfile(selectedFollowing.id);
  };

  const toggleUnfollowModal = (user: Profile) => {
    setSelectedFollowing(user);
    setIsUnfollowModal(true);
  };

  const xp = "20";

  if (isFollowingLoading)
    return (
      <div className="flex justify-center items-center h-[600px]">
        <LoadingOverlay description="Loading party..." />
      </div>
    );

  return (
    <>
      <div className="absolute w-full h-full top-10 blur-3xl z-[1] opacity-90">
        <BubblesBackground />
      </div>

      <div className="relative flex flex-col items-center justify-center pt-2 z-[10] w-full md:w-2/3 lg:w-1/2 mx-auto space-y-6 pb-60">
        <div className="relative flex items-center w-full space-x-4">
          <button
            onClick={() => navigateBack()}
            className="pl-1 bg-transparent text-white rounded-full hover:bg-gray-600"
          >
            <ChevronLeftIcon className="h-8 w-8" />
          </button>

          <div className="relative w-3/4">
            <input
              type="text"
              placeholder="Search followed users"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full p-3 pl-12 rounded-2xl border border-gray-500 bg-gray-800 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-cyan-500"
            />
            <FontAwesomeIcon
              icon={faSearch}
              className="absolute top-1/2 left-4 ml-2 transform -translate-y-1/2 text-gray-400"
            />
          </div>
        </div>

        <div className="w-4/5 space-y-3">
          {filteredFollowing &&
            filteredFollowing.map((user) => (
              <div
                key={user.id}
                onClick={() => handleNavigation(user)}
                className="bg-gradient-to-br from-[#383d44] to-[#181b21] rounded-2xl p-3 border border-[#2d3138] flex items-center justify-between cursor-pointer transition-transform transform hover:scale-105"
              >
                <div className="relative flex items-center space-x-4">
                  <div className="flex items-center">
                    <div className="flex flex-col items-center">
                      <img
                        src={user.profileImage}
                        alt="Profile"
                        className="rounded-full h-10 w-10 object-cover border border-gray-500"
                      />
                      {/* <p className="bg-gradient-to-r from-cyan-500 to-purple-500 rounded-full h-3 w-8 text-center text-white text-[8px] -mt-2">
                        {xp}
                      </p> */}
                    </div>
                    <p className="text-white ml-4">{user.gamertag}</p>
                  </div>
                </div>

                <GradientBorder from="#FF5E00" to="#FFAA00" via={["#FFAA00"]}>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleUnfollowModal(user);
                    }}
                    className="flex items-center justify-center bg-transparent hover:text-orange-700 text-orange-500 p-2 rounded-full text-sm font-semibold shadow-md hover:opacity-90 transition-opacity duration-200"
                  >
                    <FontAwesomeIcon icon={faHeartBroken} />
                  </button>
                </GradientBorder>
              </div>
            ))}
        </div>

        {isUnfollowModal && selectedFollowing && (
          <UnfollowModal
            isOpen={isUnfollowModal}
            followerName={selectedFollowing.gamertag}
            onRequestClose={() => setIsUnfollowModal(false)}
            unfollowConfirm={handleUnfollowConfirm}
          />
        )}
      </div>
    </>
  );
}
