import { useNavigate } from "react-router-dom";
import { CallToAction } from "../../../staticComponents/CallToAction";
import { Faqs } from "../../../staticComponents/Faqs";
import { Footer } from "../../../staticComponents/Footer";
import { HeaderHero } from "../../../staticComponents/Hero";
import { PrimaryFeatures } from "../../../staticComponents/PrimaryFeatures";
import { useEffect, useState } from "react";
import { Capacitor } from "@capacitor/core";
import IosInstall from "../../../staticComponents/IosInstall";

declare global {
  interface Window {
    deferredPrompt: any;
  }
}
export default function StaticPage() {
  const [isIOS, setIsIOS] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
    const iOS =
      /iPad|iPhone|iPod/.test(userAgent);

    setIsIOS(iOS);
  }, []);

  const navigate = useNavigate();
  useEffect(() => {
    const platform = Capacitor.getPlatform();
    if (platform === "web") navigate("/static");
    else navigate("/app");
  }, []);

  window.addEventListener("beforeinstallprompt", (event) => {
    event.preventDefault();
    window.deferredPrompt = event;
  });

  return (
    <div className="bg-tic-taps-grey">
      <HeaderHero />
      {isIOS && isOpen && (
        <div className="fixed bottom-0 left-0 w-full z-50 bg-white shadow-lg">
          <IosInstall setIsOpen={setIsOpen} />
        </div>
      )}
      <PrimaryFeatures />
      <CallToAction />
      <Faqs />
      <Footer />
    </div>
  );
}
