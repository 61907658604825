import GradientContainer, { GradientBorder } from "./ui/GradientContainer";
import SecondaryHeader from "./SecondaryHeader";
import React, { useEffect, useRef, useState } from "react";
import ReactConfetti from "react-confetti";
import { useAccount } from "../providers/accountProvider";
import { useNotifications } from "../providers/notificationProvider";

interface Sector {
  color: string;
  label: string;
  value: number;
}

const sectors: Sector[] = [
  { color: "#0bf", label: "Jackpot", value: 1000 },
  { color: "#212429", label: "10", value: 10 },
  { color: "#212429", label: "200", value: 200 },
  { color: "#212429", label: "50", value: 50 },
  { color: "#212429", label: "100", value: 100 },
  { color: "#212429", label: "5", value: 5 },
  { color: "#212429", label: "500", value: 500 },
];

const rand = (m: number, M: number): number => Math.random() * (M - m) + m;

const friction = 0.991;

export function RewardsSpinner({ onComplete }: { onComplete: () => void }) {
  const { claimTicTapsTokensReward } = useAccount();
  const { logWarning } = useNotifications();

  const [angVel, setAngVel] = useState(0);
  const [ang, setAng] = useState(0);
  const [spinning, setSpinning] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [hasSpun, setHasSpun] = useState(false);
  const [spinResult, setSpinResult] = useState<string>("");
  const [claimed, setClaimed] = useState(false);
  const [wonAmount, setWonAmount] = useState<number | null>(null);

  const claimToken = (): void => {
    if (!wonAmount) {
      logWarning("Please spin the wheel first", "You have not won anything");
    } else {
      claimTicTapsTokensReward(wonAmount);
      setClaimed(true);
      onComplete();
    }
  };

  const spinElRef = useRef<HTMLButtonElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const tot = sectors.length;
  const PI = Math.PI;
  const TAU = 2 * PI;
  const arc = TAU / sectors.length;
  const rad = 160; // radius of the wheel

  const getIndex = (): number => Math.floor(tot - (ang / TAU) * tot) % tot;
  const drawSector = (
    sector: Sector,
    i: number,
    ctx: CanvasRenderingContext2D,
    canvas: HTMLCanvasElement
  ): void => {
    const angle = arc * i;
    ctx.save();

    if (sector.label === "Jackpot") {
      const gradient = ctx.createLinearGradient(
        0,
        0,
        canvas.width,
        canvas.height
      );
      gradient.addColorStop(0, "#2563EB");
      gradient.addColorStop(0.5, "#A855F7");
      gradient.addColorStop(1, "#0bf");
      ctx.fillStyle = gradient;
    } else {
      ctx.fillStyle = sector.color;
    }

    ctx.beginPath();
    ctx.moveTo(rad, rad);
    ctx.arc(rad, rad, rad, angle, angle + arc);
    ctx.lineTo(rad, rad);
    ctx.fill();

    const borderGradient = ctx.createLinearGradient(
      0,
      0,
      canvas.width,
      canvas.height
    );
    borderGradient.addColorStop(0, "#0bf");
    borderGradient.addColorStop(0.5, "#2563EB");
    borderGradient.addColorStop(1, "#A855F7");
    ctx.strokeStyle = borderGradient;
    ctx.lineWidth = 3;

    ctx.shadowColor = "rgba(0, 0, 0, 0.6)";
    ctx.shadowBlur = 15;
    ctx.shadowOffsetX = 2;
    ctx.shadowOffsetY = 2;

    ctx.stroke();

    ctx.shadowColor = "transparent";
    ctx.shadowBlur = 0;
    ctx.shadowOffsetX = 0;
    ctx.shadowOffsetY = 0;

    ctx.translate(rad, rad);
    ctx.rotate(angle + arc / 2);
    ctx.textAlign = "right";
    ctx.fillStyle = "#fff";
    ctx.font = "bold 22px sans-serif";
    ctx.fillText(sector.label, rad - 20, 10);

    ctx.restore();
  };

  const rotate = (ctx: CanvasRenderingContext2D): void => {
    const sector = sectors[getIndex()];
    ctx.canvas.style.transform = `rotate(${ang - PI / 2}rad)`;
    if (spinElRef.current) {
      spinElRef.current.textContent = !angVel ? "SPIN" : sector.label;
      if (!angVel || sector.label === "Jackpot") {
        spinElRef.current.style.background =
          "linear-gradient(to left, #0bf, #2563EB, #A855F7)";
        spinElRef.current.style.color = "#fff";
      } else {
        spinElRef.current.style.background = sector.color;
        spinElRef.current.style.color = "#fff";
      }
    }
  };

  const frame = (): void => {
    if (!angVel) return;

    setAngVel((prev) => prev * friction);

    if (angVel < 0.002) {
      setAngVel(0);
      setSpinning(false);
      setHasSpun(true);

      const resultIndex = getIndex();
      const resultLabel = sectors[resultIndex].label;
      const resultValue = sectors[resultIndex].value;

      setSpinResult(resultLabel);
      setShowConfetti(true);

      setWonAmount(resultValue);
    }

    setAng((prevAng) => (prevAng + angVel) % TAU);
  };

  const spinWheel = (): void => {
    if (!angVel && !hasSpun) {
      setSpinning(true);
      setSpinResult("Spinning...");
      setAngVel(rand(0.25, 0.45));
    }
  };

  const draw = (ctx: CanvasRenderingContext2D, canvas: HTMLCanvasElement) => {
    if (!ctx || !canvas) return;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    sectors.forEach((sector, i) => drawSector(sector, i, ctx, canvas));
    rotate(ctx);
    frame();
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    const drawInterval = () => draw(ctx, canvas);
    const interval = setInterval(drawInterval, 1000 / 60);

    return () => clearInterval(interval);
  }, [angVel, ang]);

  return (
    <div className="relative flex flex-col items-center justify-between min-h-screen  text-white">
      <div className="absolute w-full h-full">
        <img src="backgrounds/bubblesBackground.svg" alt="" />
      </div>
      <div className="relative w-full h-full">
        <div className="absolute w-full h-full backdrop-blur"></div>
        <div className="relative h-full flex flex-col justify-between">
          <div className="flex flex-col text-center min-h-[80px] xs:mt-4">
            <p className="text-2xl font-semibold mb-1">
              {wonAmount && `You won ${wonAmount} tokens!`}
            </p>
            <p className="text-4xl font-semibold">
              <span className="text-white ">
                {!wonAmount && "Give it a go!"}
              </span>
            </p>
            {!claimed && hasSpun && !spinning && (
              <p className="text-2xl font-semibold mt-4 text-purple-400">
                You landed on:{" "}
                <span className="font-extrabold">{spinResult}</span>
              </p>
            )}
          </div>

          <div className="relative flex justify-center items-center mt-12">
            <GradientBorder rounded="rounded-full" padding="p-0.5">
              <div className="relative p-6 rounded-full bg-zinc-900  shadow-glow">
                <div className="absolute top-0 left-1/2 transform -translate-x-1/2 w-0 h-0 border-l-[10px] border-r-[10px] border-t-[15px] border-l-transparent border-r-transparent border-t-cyan-500"></div>

                <div id="wheelOfFortune" className="relative">
                  <canvas
                    ref={canvasRef}
                    id="wheel"
                    width="320"
                    height="320"
                    style={{
                      borderRadius: "50%",
                      display: "block",
                      margin: "auto",
                    }}
                  />
                  <button
                    ref={spinElRef}
                    onClick={() => spinWheel()}
                    className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-14 h-14 bg-white text-white shadow-lg shadow-black font-bold rounded-full text-sm disabled:opacity-50"
                    disabled={spinning || hasSpun || claimed}
                  >
                    SPIN
                  </button>
                </div>
              </div>
            </GradientBorder>
          </div>

          {showConfetti && <ReactConfetti />}

          <div className="flex items-center justify-center h-screen">
            <div className="w-full items-center max-w-sm z-[30] mb-12">
              <GradientContainer styles="rounded-full mx-auto w-full">
                <button
                  onClick={() => claimToken()}
                  className="w-full text-white shadow-glow font-bold text-xl tracking-wide px-8 py-3.5 rounded-full disabled:opacity-50"
                  disabled={!wonAmount || claimed}
                >
                  Claim
                </button>
              </GradientContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
