import React, { ReactNode } from "react";
import { Chat } from "stream-chat-react";
import { StreamChat } from "stream-chat";
import { useAccount } from "./accountProvider";

interface ChatProviderProps {
  children: ReactNode;
}

const apiKey = "rp37rkfubtfp";
const chatClient = StreamChat.getInstance(apiKey!);

//Not Technically a provider but does contain a provider that needs to wrap

export const ChatProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}: ChatProviderProps) => {
  const { account } = useAccount();

  React.useEffect(() => {
    if (account) {
      getClientInstance();
    }
  }, [account]);

  const getClientInstance = async () => {
    await chatClient?.connectUser(
      {
        //Checking that this exists in the useEffect but still adding defaults just to be safe
        id: account?.id ?? "",
        name: account?.nickName ?? "",
        image: account?.profileImage ?? "",
      },
      account?.chatToken ?? ""
    );
  };

  return chatClient == null ? (
    <>{children}</>
  ) : (
    <Chat client={chatClient}>{children}</Chat>
  );
};
