import { Participant } from "../../../types";
import { Get } from "../../fetch.wrapper";
import { routes } from "../../routes";

export default async function GetParticipants(
  partyId: string
): Promise<Participant[]> {
  const data = await Get(routes.getParticipants(partyId));

  return data.map(
    (d: any) =>
      ({
        profile: {
          email: d.email,
          gamertag: d.gamertag,
          id: d.user_id,
          profileImage: d.profile_picture,
          username: d.name,
          xp: d.xp,
        },
        buyIn: d.buy_in_amount,
        highSchore: d.score,
        hasPlayed: d.has_played,
      } as Participant)
  ) as Participant[];
}
