import { IoChatbubble, IoShareSocial } from "react-icons/io5";
import { PartyProvider, useParty } from "src/providers/partyProvider";
import { useParams } from "react-router-dom";
import { useState } from "react";
import GradientContainer from "src/components/ui/GradientContainer";
import { Sheet } from "react-modal-sheet";
import PartyControls from "src/components/PartyControls";
import ParticipantsOverview from "src/components/ParticipantsOverview";
import { useAccount } from "src/providers/accountProvider";
import useTTRouter from "src/router/hooks/useTTRouter";
import QRCode from "react-qr-code";
import { Participant } from "src/types";
import PullToRefresh from "react-simple-pull-to-refresh";
import { usePartyBackground } from "src/providers/layoutProvider";
import { LoadingOverlay } from "src/components/LoadingOverlay";

export function PartyPage() {
  const { partyId } = useParams();

  if (!partyId) {
    return <></>;
  }

  return (
    <PartyProvider partyId={partyId}>
      <_Party />
    </PartyProvider>
  );
}
function _Party() {
  const {
    leaderBoard,
    party,
    isPartyQueryLoading,
    participants,
    isParticipantsLoading,
    refreshParty,
    refreshParticipants,
    joinPartyById,
    leavePartyById,
    endPartyById,
    isEndPartyLoading,
    isJoinPartyLoading,
    isLeavePartyLoading,
  } = useParty();
  const { account } = useAccount();
  const {
    navigateToPartyChat,
    navigateToLeaderboard,
    navigateToGame,
    navigateToUserProfile,
  } = useTTRouter();

  const [isParticipantChanging, setIsParticipantChanging] = useState(false);

  const gameIcon = party?.game?.icon ?? "default-icon-url";
  usePartyBackground(gameIcon);
  const [shareSheetOpen, setShareSheetOpen] = useState(false);

  const isPartyOfUser =
    !isPartyQueryLoading && account?.id === party?.createdById;

  const isParticipant =
    !isPartyQueryLoading &&
    !!participants?.find((p) => p.profile.id === account?.id);

  const hasPlayedAlready =  !!participants?.find((p) => p.profile.id === account?.id)?.hasPlayed;

  const isPartyReady = !isPartyQueryLoading && !!party;

  const isLoading =
    isEndPartyLoading || isJoinPartyLoading || isLeavePartyLoading;

  function handleChatRedirect() {
    navigateToPartyChat(party?.chatChannelId ?? "");
  }

  function handleLeaderboardRedirect() {
    navigateToLeaderboard(party?.id ?? "");
  }

  function handleNavigateToGame() {
    const gameId = party?.game.id;
    const partyId = party?.id;
    if (!gameId || !partyId) return;
    navigateToGame(party.game.id, party.id);
  }

  async function handleJoinPartyById() {
    if (!party) return;
    setIsParticipantChanging(true);
    try {
      await joinPartyById(party);
      await refreshParticipants();
    } finally {
      setIsParticipantChanging(false);
    }
  }

  async function handleLeavePartyById() {
    if (!party) return;
    setIsParticipantChanging(true);
    try {
      await leavePartyById(party);
      await refreshParticipants();
    } finally {
      setIsParticipantChanging(false);
    }
  }

  function closePartyById() {
    if (!party) return;
    endPartyById(party);
  }

  async function handleRefresh() {
    setIsParticipantChanging(true);
    refreshParty();
    refreshParticipants();
    setIsParticipantChanging(false);
  }

  if (isPartyQueryLoading) {
    return (
      <div className="flex justify-center items-center h-[600px]">
        <LoadingOverlay description="Loading party..." />
      </div>
    );
  }
  return (
    <PullToRefresh onRefresh={handleRefresh}>
      <>
        <div className="relative bg-cover bg-center flex justify-center pb-60">
          <div className="w-full md:w-2/3 lg:w-1/2 h-full gap-y-5 p-4">
            <div className="flex flex-row items-center gap-x-2 py-2 ">
              {party && (
                <p className="text-white font-extrabold text-4xl">
                  {party.name}
                </p>
              )}
              <button
                disabled={!isParticipant || !isPartyOfUser || hasPlayedAlready}
                className="h-fit"
                onClick={() => setShareSheetOpen(true)}
              >
                <IoShareSocial size={28} color="lightgray" />
              </button>
            </div>
            {!!party &&
              (isPartyReady ? (
                <PartyControls
                  party={party}
                  isOwner={isPartyOfUser}
                  isParticipant={isParticipant}
                  joinParty={handleJoinPartyById}
                  leaveParty={handleLeavePartyById}
                  startGame={handleNavigateToGame}
                  openLeaderboard={handleLeaderboardRedirect}
                  endParty={closePartyById}
                  isLoading={
                    isParticipantsLoading || isParticipantChanging || isLoading
                  }
                />
              ) : (
                <div className="loading-placeholder">
                  Loading Party Controls...
                </div>
              ))}
            <div>
              <ParticipantsOverview
                seeParticipantsProfile={(participant: Participant) =>
                  navigateToUserProfile(participant.profile.id)
                }
                refreshParticipants={refreshParticipants}
                isParticipantsLoading={
                  isParticipantsLoading || isParticipantChanging || isLoading
                }
                participants={participants}
              />
            </div>
          </div>

          <Sheet
            isOpen={shareSheetOpen}
            onClose={() => setShareSheetOpen(false)}
            detent="content-height"
          >
            <Sheet.Container>
              <Sheet.Header className="bg-tic-taps-grey" />
              <Sheet.Content className="bg-tic-taps-grey flex flex-row items-center">
                <div className="flex flex-col justify-around align-center content-center p-8 ">
                  <QRCode
                    size={256}
                    style={{ height: "75%" }}
                    value={"tictaps://tictaps.com/party"}
                    viewBox={`0 0 256 256`}
                  />

                  <div className="flex flex-row align-center justify-center my-5">
                    {party ? (
                      <span className="text-white">{party.inviteCode}</span>
                    ) : (
                      <p className="text-white bg-gray-300 h-10"></p>
                    )}
                  </div>

                  <button className="text-white h-12 font-semibold bg-gradient-to-br from-[#00FFAA] via-[#4579F5] to-[#9C42F5] w-full rounded-lg px-2 py-1">
                    Share
                  </button>
                </div>
              </Sheet.Content>
            </Sheet.Container>
          </Sheet>
        </div>

        {isPartyOfUser || isParticipant ? (
          <div className="fixed bottom-24 right-6">
            <GradientContainer styles="rounded-full text-white">
              <button className="p-2" onClick={() => handleChatRedirect()}>
                <IoChatbubble size={18} />
              </button>
            </GradientContainer>
          </div>
        ) : (
          <div className="fixed bottom-24 right-6 opacity-50">
            <GradientContainer styles="rounded-full text-white">
              <div className="p-2">
                <IoChatbubble size={18} />
              </div>
            </GradientContainer>
          </div>
        )}
      </>
    </PullToRefresh>
  );
}
