import { Post } from "../../fetch.wrapper";
import { routes } from "../../routes";

export default async function AddTicTapsTokens(amount: Number) {
  await Post(
    routes.fundWallet(),
    {
      amount,
      currency_type: "AWARD",
      currency_code: "TTT",
    }
  );

  return true;
}
