
import { AppGalleryButton } from 'react-mobile-app-button'
import MobileAppButtons from './MobileAppButtons'
import BubblesBackground from './BubblesBackground'
import { CircleBackgroundGray } from './CircleBackground'
import { Container } from './Container'

export function CallToAction() {
  return (
    <section
    id="download"
    className="relative overflow-hidden bg-tic-taps-grey py-48"
     >
    <div className="absolute top-40 left-1/2 transform -translate-x-1/2 overflow-hidden w-full opacity-75 scale-x-100 blur-3xl z-0">
      <BubblesBackground />
    </div>
    <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 sm:left-1/2 sm:top-1/2">
      <CircleBackgroundGray color="#18181B" className="animate-spin-slower" />
    </div>
    <Container className="relative z-[30]">
      <div className="mx-auto max-w-md text-center">
        <section>
          <h2 className="text-3xl font-medium tracking-tight bg-clip-text text-transparent bg-gradient-to-r from-cyan-500 via-blue-600 to-purple-500 sm:text-4xl">
            Start Gaming!
          </h2>
        </section>
        <p className="mt-4 text-lg text-gray-300">
          Join Tictaps, where gaming meets crypto and every play could unlock real rewards. Are you ready to level up your game and your investments?
        </p>
  
        <div className="mt-8 flex justify-center">
          <MobileAppButtons />
        </div>
      </div>
    </Container>
  </section>
  
  )
}
