import { routes } from "../../routes";
import { Get } from "../../fetch.wrapper";
import { User } from "stream-chat/dist/types/types";
import { Profile } from "../../../types";

export default async function GetUser(id: string): Promise<Profile> {
  const data = await Get(routes.user(id));
  return {
      id: data.user_id,
      username: data.nickname,
      gamertag: data.gamer_tag,
      email: data.email,
      profileImage: data.profile_image,
      xp: data.xp, 
  };
}