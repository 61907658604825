import GradientContainer from "./ui/GradientContainer";

export function RewardCard({
  title,
  description,
  onClick,
}: Readonly<{
  title: string;
  description: string;
  onClick: () => void;
}>) {
  return (
    <div className="flex backdrop-blur-sm bg-gradient-to-br to-white/10 via-[#34414d]/10 from-[#34414d]/10 flex-row items-center justify-between border-hairline border-[#34414d] rounded-xl p-1 px-4">
      <div className="flex flex-col gap-x-2 mr-2">
        <p className="text-white font-semibold text-lg">{title}</p>
        <p className="text-white text-sm">{description}</p>
      </div>
      <GradientContainer styles="rounded-xl">
        <button
          onClick={() => onClick()}
          className="text-white font-semibold text-xs px-4 py-2"
        >
          Claim
        </button>
      </GradientContainer>
    </div>
  );
}
