import { PartiesHorizontalList } from "../../components/PartiesList";
import { JoinPartyByCodeCard } from "src/components/JoinPartyCard";
import useTTRouter from "../../router/hooks/useTTRouter";
import { Party } from "../../types";
import { useBubblesBackground } from "../../providers/layoutProvider";
import { useParties } from "../../providers/partiesProvider";
import { useState } from "react";
import { RewardCard } from "../../components/RewardCard";
import { RewardsSpinner } from "../../components/RewardsSpinner";
import { Sheet } from "react-modal-sheet";
import PullToRefresh from "react-simple-pull-to-refresh";
import { AddPartyCard } from "src/components/AddPartyCard";

export default function Main() {
  useBubblesBackground();
  const { publicParties, privateParties, isPartiesLoading, refreshParties } =
    useParties();
  const { navigateToParty } = useTTRouter();
  const [isRewardsOpen, setIsRewardsOpen] = useState(false);

  function handleRewardsClose() {
    setIsRewardsOpen(false);
  }

  function handleRewardsOpen() {
    setIsRewardsOpen(true);
  }

  function handlePartySelection(party: Party) {
    navigateToParty(party.id);
  }


  async function handleRefresh() {
    refreshParties();
  }

  if (!publicParties) return <></>;

  return (
    <PullToRefresh onRefresh={handleRefresh}>
      <div className="flex flex-col gap-y-2 w-full md:w-2/3 lg:w-1/2 h-fit pb-60">
        {/* <PartiesCarousel /> */}

        <div className="flex flex-col px-2 gap-y-2">
          <RewardCard
            description="Get your free TTokens now!"
            title="Free TTokens!"
            onClick={handleRewardsOpen}
          />
          <JoinPartyByCodeCard />
        </div>

        <PartiesHorizontalList
          title={"Public Parties 🥳"}
          onPartyClick={(party: Party) => handlePartySelection(party)}
          parties={publicParties}
          isLoading={isPartiesLoading}
          fallback={() => (
            <div className="w-full flex items-center justify-center h-40">
              <p className="text-xs text-white">Parties on the way!</p>
            </div>
          )}
        />

        <PartiesHorizontalList
          title={"Private Parties 🎉"}
          onPartyClick={(party: Party) => handlePartySelection(party)}
          parties={privateParties}
          isLoading={isPartiesLoading}
          fallback={AddPartyCard}
          firstElement={AddPartyCard}
        />

        <Sheet isOpen={isRewardsOpen} onClose={handleRewardsClose}>
          <Sheet.Container>
            <Sheet.Header className="bg-tic-taps-grey" />
            <Sheet.Content className="bg-tic-taps-grey p-0">
              <RewardsSpinner onComplete={handleRewardsClose} />
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop className="bg-tic-taps-grey" />
        </Sheet>
      </div>
    </PullToRefresh>
  );
}
