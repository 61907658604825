import { Participant } from "../types";
import { useState } from "react";
import { ReactComponent as PeopleIcon } from "../assets/peopleIcon.svg";
import { LoadingSpinner } from "./LoadingOverlay";
import { ArrowPathIcon, TrophyIcon } from "@heroicons/react/24/solid";
import { GradientBorder } from "./ui/GradientContainer";
import { useAccount } from "../providers/accountProvider";
import { Transition } from "@headlessui/react";
import useTTRouter from "src/router/hooks/useTTRouter";

export default function ParticipantsOverview({
  participants,
  isParticipantsLoading,
  refreshParticipants,
  seeParticipantsProfile,
}: {
  participants: Participant[] | undefined;
  isParticipantsLoading: boolean;
  refreshParticipants: () => void;
  seeParticipantsProfile: (participant: Participant) => void;
}) {
  const [seeAllParticipants, setSeeAllParticipants] = useState(false);
  const { account } = useAccount();
  const { navigateToMyProfile } = useTTRouter();

  if (!participants) {
    return (
      <div className="flex justify-center items-center h-[600px]">
        <p className="text-gray-400">Unable to load participants.</p>
      </div>
    );
  }

  return (
    <>
      <Transition
        show={isParticipantsLoading}
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="absolute inset-0 flex justify-center items-center bg-opacity-50">
        </div>
      </Transition>

      <Transition
        show={
          !isParticipantsLoading && !!participants && participants.length > 0
        }
        enter="transition-all duration-300 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition-all duration-300 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="flex flex-col gap-x-2 h-84 py-4">
          <div className="flex flex-row gap-x-12">
            <div className="w-1/2 flex flex-row items-center gap-x-2">
              <p className="text-white font-semibold text-lg">Party people</p>
              <p className="text-gray-300 text-lg">{participants?.length}</p>
            </div>
            {participants?.length > 0 && (
              <div className="w-1/3 flex flex-row justify-end space-x-2">
                <p
                  className="text-white text-lg cursor-pointer"
                  onClick={() => setSeeAllParticipants(true)}
                >
                  See all
                </p>
              </div>
            )}
          </div>

          {!seeAllParticipants && (
            <div className="flex flex-col pt-2 space-y-2 w-full px-2">
              {participants?.map((participant) => (
                <button
                key={participant.profile.id}
                onClick={() => 
                  account?.id === participant.profile.id
                    ? navigateToMyProfile()
                    : seeParticipantsProfile(participant)
                }
                className="bg-[#1f2328] rounded-3xl p-5 border border-[#333a42] flex items-center justify-between shadow-lg hover:shadow-xl transition-all duration-300"
              >
                  <div className="relative flex items-center space-x-4">
                    <div className="flex items-center space-x-2">
                      {account?.id === participant.profile.id ? (
                        <GradientBorder
                          from="#FF5E00"
                          to="#FFAA00"
                          via={["#FFAA00"]}
                          padding="p-0.5"
                          rounded="rounded-full"
                        >
                          <img
                            src={participant.profile.profileImage}
                            alt={`${participant.profile.gamertag} Profile`}
                            className="rounded-full h-12 w-12 object-cover transform transition-transform duration-300 hover:scale-105"
                          />
                        </GradientBorder>
                      ) : (
                        <GradientBorder padding="p-0.5" rounded="rounded-full">
                          <img
                            src={participant.profile.profileImage}
                            alt={`${participant.profile.gamertag} Profile`}
                            className="rounded-full h-12 w-12 object-cover transform transition-transform duration-300 hover:scale-105"
                          />
                        </GradientBorder>
                      )}
                      <div className="flex flex-col space-y-1">
                        <p className="text-white truncate max-w-40 font-semibold text-lg tracking-wide">
                          {participant.profile.gamertag}
                        </p>
                        <p className="bg-[#444d56] rounded-full h-4 w-16 text-left text-xs text-gray-300 font-medium">
                          <span className="pl-2">
                            {participant.profile.xp} XP
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center space-x-1">
                    <div className="flex flex-col items-center">
                      <TrophyIcon className="w-6 h-6 text-orange-500" />
                      <div className="h-6 w-6 flex items-center justify-center text-lg text-orange-500 font-bold">
                        {participant.profile.xp}
                      </div>
                    </div>
                  </div>
                </button>
              ))}
            </div>
          )}
        </div>
      </Transition>

      <Transition
        show={!isParticipantsLoading && participants?.length === 0}
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="flex flex-col items-center p-2 justify-center h-full rounded-xl bg-gradient-to-br from-[#31353b] to-[#181b21] border border-[#34414d]">
          <p className="text-xs text-gray-400">
            You don't have a single person yet
          </p>
          <div className="h-42">
            <PeopleIcon />
          </div>
        </div>
      </Transition>
    </>
  );
}
