import { Currency, Game } from "src/types";
import { useBubblesBackground } from "src/providers/layoutProvider";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import useGames from "src/hooks/useGames";
import { useState } from "react";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsRotate,
  faChevronDown,
  faGamepad,
  faLock,
  faLockOpen,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { Sheet } from "react-modal-sheet";
import { GradientBorder } from "src/components/ui/GradientContainer";
import useParties from "src/hooks/useParties";
import useTTRouter from "src/router/hooks/useTTRouter";
import { currencies } from "src/providers/accountProvider";
import { useNotifications } from "src/providers/notificationProvider";
import { LoadingSpinner } from "src/components/LoadingOverlay";

export interface CreatePartySettings {
  name: string;
  isPrivate: boolean;
  buyIn: number;
  currency: Currency;
  game: Game;
  duration: string;
  inviteCode: string | null;
}

function generateRandomLetter() {
  const letters = "abcdefghijklmnopqrstuvwxyz";
  return letters[Math.floor(Math.random() * letters.length)];
}

function generateCode(len = 5) {
  let randomWord = "";
  for (let i = 0; i < len; i++) {
    randomWord += generateRandomLetter();
  }
  return randomWord;
}

export function CreatePartyPage() {
  useBubblesBackground();
  const { data: games } = useGames();
  const { navigateToParty } = useTTRouter();
  const { logError } = useNotifications();
  const { createParty } = useParties();

  const [partyDetails, setPartyDetails] = useState({
    currency: null as Currency | null,
    name: null as string | null,
    inviteCode: null as string | null,
    buyIn: "0",
    showGameSelection: false,
    game: null as Game | null,
    isPrivate: true,
    isPartyCreating: false,
  });

  const acceptableCurrencies = currencies.filter((c) =>
    ["USD", "TTT"].includes(c.code)
  );

  async function onSubmit() {
    const { name, inviteCode, buyIn, game, currency, isPrivate } = partyDetails;
    if (!name || !inviteCode || !buyIn || !game || !currency) {
      logError("Invalid form", "Please fill out all fields");
      return;
    }
    setPartyDetails((prev) => ({ ...prev, isPartyCreating: true }));
    try {
      const partyId = await createParty({
        name,
        isPrivate,
        buyIn: Number(buyIn),
        currency: currency,
        game,
        duration: "5",
        inviteCode,
      });
      navigateToParty(partyId);
    } catch (err) {
      logError("Create Party", "Failed to create party");
    }
    setPartyDetails((prev) => ({ ...prev, isPartyCreating: false }));
  }

  return (
    <>
      <div className="relative flex flex-col gap-y-6 px-4 min-h-screen pb-60">
        <div className="w-full md:w-2/3 lg:w-1/2 mx-auto">
          <div className="relative w-full h-full">
            <div className="absolute w-full h-full backdrop-blur-3xl rounded-xl"></div>
            <div className="flex flex-col font-semibold space-x-1 text-white border-[#34414d]  relative  bg-gradient-to-tl to-white/10 via-[#34414d]/10 from-[#1b2229]/70 rounded-lg p-4 space-y-4">
              <div className="flex flex-col space-y-2">
                <p className="text-white text-sm font-medium">
                  Name of the party 😊
                </p>
                <div className="flex">
                  <input
                    className=" w-full placeholder:text-gray-300 font-light flex-grow border-y-hairline border-l-hairline border-[#34414d] rounded-l-2xl p-4 bg-tic-taps-grey/20 text-white"
                    placeholder="Enter Party Name"
                    autoCapitalize="none"
                    autoComplete="off"
                    autoCorrect="off"
                    onChange={(e) =>
                      setPartyDetails((prev) => ({
                        ...prev,
                        name: e.target.value,
                      }))
                    }
                    value={partyDetails.name ?? ""}
                  />
                  <button
                    onClick={() =>
                      setPartyDetails((prev) => ({
                        ...prev,
                        isPrivate: !prev.isPrivate,
                      }))
                    }
                    className="w-16  rounded-r-2xl to-[#181b21] bg-gradient-to-br from-[#31353b]"
                  >
                    {partyDetails.isPrivate ? (
                      <FontAwesomeIcon
                        icon={faLock}
                        size="1x"
                        color="#ffffff"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faLockOpen}
                        size="1x"
                        color="#ffffff"
                      />
                    )}
                  </button>
                </div>
              </div>

              <div className="flex flex-col space-y-2">
                <p className="text-white text-sm font-medium">Game</p>

                <button
                  onClick={() =>
                    setPartyDetails((prev) => ({
                      ...prev,
                      showGameSelection: true,
                    }))
                  }
                  className="flex justify-start items-center space-x-2 w-full font-light flex-grow border-hairline border-[#34414d] rounded-2xl p-4 bg-tic-taps-grey/20 text-white"
                >
                  {partyDetails.game ? (
                    <>
                      <img
                        src={partyDetails.game?.icon}
                        className="w-10 h-10 rounded-lg"
                      />
                      <p className="text-lg font-semibold">
                        {partyDetails.game?.name}
                      </p>
                    </>
                  ) : (
                    <>
                      <FontAwesomeIcon icon={faGamepad} />
                      <p className="text-gray-300">Select a game</p>
                    </>
                  )}
                </button>
              </div>

              <div className="flex flex-col space-y-2">
                <p className="text-white text-sm font-medium">Invite Code</p>

                <div className="flex">
                  <input
                    className=" w-full placeholder:text-gray-300 font-light flex-grow border-y-hairline border-l-hairline border-[#34414d] rounded-l-2xl p-4 bg-tic-taps-grey/20 text-white"
                    placeholder="Write an invite code"
                    autoCapitalize="none"
                    autoComplete="off"
                    autoCorrect="off"
                    onChange={(e) =>
                      setPartyDetails((prev) => ({
                        ...prev,
                        inviteCode: e.target.value,
                      }))
                    }
                    value={partyDetails.inviteCode ?? ""}
                  />
                  <button
                    onClick={() =>
                      setPartyDetails((prev) => ({
                        ...prev,
                        inviteCode: generateCode(),
                      }))
                    }
                    className="w-16  rounded-r-2xl to-[#181b21] bg-gradient-to-br from-[#31353b]"
                  >
                    <FontAwesomeIcon
                      icon={faArrowsRotate}
                      size="1x"
                      color="#ffffff"
                    />
                  </button>
                </div>
              </div>

              <div className="flex flex-col space-y-2">
                <p className="text-white text-sm font-medium">Currency Type</p>

                <Listbox
                  value={partyDetails.currency}
                  onChange={(c) =>
                    setPartyDetails((prev) => ({ ...prev, currency: c }))
                  }
                >
                  <ListboxButton
                    className={clsx(
                      "w-full font-light flex-grow border-hairline border-[#34414d] rounded-2xl p-4 bg-tic-taps-grey/20 text-white"
                    )}
                  >
                    <div className="w-full flex items-center justify-between">
                      {partyDetails.currency ? (
                        <p>{partyDetails.currency.code}</p>
                      ) : (
                        <p className="text-gray-300">Select a currency</p>
                      )}
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className="group pointer-events-none size-4 fill-white/60"
                      />
                    </div>
                  </ListboxButton>
                  <ListboxOptions
                    anchor="bottom"
                    transition
                    className={clsx(
                      "w-[var(--button-width)] backdrop-blur-sm rounded-xl border border-white/5 bg-white/5 p-1 [--anchor-gap:var(--spacing-1)] focus:outline-none",
                      "transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0"
                    )}
                  >
                    {acceptableCurrencies.map((currency, index) => (
                      <ListboxOption
                        key={"currency_" + index}
                        value={currency}
                        className="group flex cursor-default items-center gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-white/10"
                      >
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          className="invisible size-4 fill-white group-data-[selected]:visible"
                        />

                        <div className="text-sm/6 text-white">
                          {currency.code}
                        </div>
                      </ListboxOption>
                    ))}
                  </ListboxOptions>
                </Listbox>
              </div>

              <div className="flex flex-col space-y-2">
                <p className="text-white text-sm font-medium">Buy in</p>

                <input
                  className=" w-full placeholder:text-gray-300 font-light flex-grow border-hairline border-[#34414d] rounded-2xl p-4 bg-tic-taps-grey/20 text-white"
                  placeholder="Buy In"
                  autoCapitalize="none"
                  autoComplete="off"
                  autoCorrect="off"
                  type="number"
                  onChange={(e) =>
                    setPartyDetails((prev) => ({
                      ...prev,
                      buyIn: e.target.value,
                    }))
                  }
                  value={partyDetails.buyIn}
                />
              </div>

              <div>
                <button
                  disabled={partyDetails.isPartyCreating}
                  onClick={() => onSubmit()}
                  className="my-2 disabled:bg-none transition-all disabled:bg-gray-500 py-4 hover:shadow-glow ease-in-out duration-150 bg-gradient-to-br from-[#00FFAA] via-[#4579F5] to-[#9C42F5] w-full rounded-2xl text-white"
                >
                  {partyDetails.isPartyCreating ? (
                    <LoadingSpinner />
                  ) : (
                    "Create Party"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Sheet
        isOpen={partyDetails.showGameSelection}
        onClose={() =>
          setPartyDetails((prev) => ({ ...prev, showGameSelection: false }))
        }
      >
        <Sheet.Container>
          <Sheet.Header className="bg-tic-taps-grey" />
          <Sheet.Content className="bg-tic-taps-grey">
            <ChooseGame
              options={games!}
              value={partyDetails.game}
              onChange={(game) =>
                setPartyDetails((prev) => ({
                  ...prev,
                  game,
                  showGameSelection: false,
                }))
              }
            />
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop className="bg-tic-taps-grey" />
      </Sheet>
    </>
  );
}

export function ChooseGame({
  value,
  options,
  onChange,
}: {
  value: Game | null;
  options: Game[];
  onChange: (game: Game) => void;
}) {
  const [query, setQuery] = useState<string | null>(null);

  function isSelected(current: Game | null, game: Game) {
    return current !== null && current.id === game.id;
  }

  const filteredOptions = query
    ? options.filter((option) =>
        option.name.toLowerCase().includes(query.toLowerCase())
      )
    : options;

  return (
    <div className="flex flex-col items-center h-screen w-screen">
      <div className="flex flex-col items-center h-full gap-y-4 px-4 w-full">
        <p className="text-center text-xl font-bold text-white">Choose game</p>
        <div className="relative w-full px-2">
          <input
            className="w-full p-3 pl-12 rounded-2xl border border-gray-500 bg-zinc-900 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-cyan-500"
            placeholder="Search by name"
            onChange={(e) => setQuery(e.target.value)}
            value={query || ""}
          />
          <FontAwesomeIcon
            icon={faSearch}
            className="absolute top-1/2 left-4 ml-2 transform -translate-y-1/2 text-gray-400"
          />
        </div>

        <div className="flex flex-wrap w-full justify-center gap-x-2 gap-y-2">
          {filteredOptions.map((option) => (
            <div
              className="shadow-md h-40 w-40 my-2 relative cursor-pointer"
              onClick={() => onChange(option)}
              key={option.id}
            >
              {isSelected(value, option) ? (
                <GradientBorder>
                  <img
                    className="rounded-2xl object-cover"
                    src={option.icon}
                    alt={option.name}
                  />
                </GradientBorder>
              ) : (
                <img
                  className="rounded-2xl object-cover"
                  src={option.icon}
                  alt={option.name}
                />
              )}
              <p className="font-bold text-white text-center">{option.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
