import { Game, Party } from "../../types";
import { Get } from "../fetch.wrapper";
import { routes } from "../routes";
import { DateTime } from "luxon";
function ToParty(e: any) {
  const createdDateTime = DateTime.fromISO(e.created_date, {
    zone: "UTC",
  }).setZone("Africa/Johannesburg");
  const endDateTime = DateTime.fromISO(e.end_date_time, {
    zone: "UTC",
  }).setZone("Africa/Johannesburg");

  return {
    id: e.id,
    inviteCode: e.invite_code,
    name: e.name,
    currencyType: e.currency_type,
    currencyCode: e.currency_code,
    buyInAmount: Number(e.buy_in_amount),
    createdById: e.created_by_id,
    createdDate: createdDateTime.toJSDate(),
    endDate: endDateTime.toJSDate(),
    participants: e.participants,
    status: e.status,
    game: {
      id: e.game.id,
      description: e.game.description,
      name: e.game.name,
      icon: e.game.icon_url,
      gameplayPreview: e.game.in_game_url,
      gamePreview: e.game.pre_game_url,
    } as Game,
  } as Party;
}

export default async function GetPrivateParties(): Promise<Party[]> {
  const data = await Get(
    routes.parties() + `?visibility=private&status=ACTIVE`
  );

  return data.map(ToParty);
}
