import {
  faCheck,
  faCircleInfo,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createContext, useContext, useEffect, useState } from "react";
import errors from "./errors";
import { v4 as uuid } from "uuid";

export interface TTError {
  code: string;
  error: Error;
  title?: string;
  message?: string;
}

export function isTTError(error: any): error is TTError {
  return error.type.includes("tterror");
}

export function TTError(code: string, title?: string, message?: string) {
  return {
    type:"tterror",
    code,
    title,
    message,
    error: new Error(),
  };
}

export const NotificationContext = createContext<{
  notifications: Notification[];
  logError: (title: string, message: string) => void;
  logErrorCode: (errror: TTError) => void;
  logWarning: (title: string, message: string) => void;
  removeNotification: (id: string) => void;
} | null>(null);

enum NotificationType {
  SUCCESS = "success",
  ERROR = "error",
  INFO = "info",
  WARNING = "warning",
}

interface Notification {
  id: string;
  title: string;
  message: string;
  type: NotificationType;
}

function WarningNotification({
  title,
  message,
  onClose,
}: {
  title: string;
  message: string;
  onClose: () => void;
}) {
  useEffect(() => {
    const timeId = setTimeout(() => {
      onClose();
    }, 5000);

    return () => {
      clearTimeout(timeId);
    };
  }, []);

  return (
    <div
      className={`shadow-lg bg-tic-taps-grey shadow-[#ffab00] w-full rounded-2xl h-24 flex flex-col p-2 border-hairline border-[#ffab00]`}
    >
      <div className={`flex items-center space-x-1  text-xs text-[#ffab00]`}>
        <FontAwesomeIcon icon={faTriangleExclamation} />
        <p>Warning</p>
      </div>
      <p className="text-white font-semibold">{title}</p>
      <p className="text-white text-sm font-light">{message}</p>
    </div>
  );
}

function SuccessNotification({
  title,
  message,
  onClose,
}: {
  title: string;
  message: string;
  onClose: () => void;
}) {
  useEffect(() => {
    const timeId = setTimeout(() => {
      onClose();
    }, 2000);

    return () => {
      clearTimeout(timeId);
    };
  }, []);

  return (
    <div
      className={`shadow-lg bg-tic-taps-grey shadow-[#189057] w-full rounded-2xl h-24 flex flex-col p-2 border-hairline border-[#189057]`}
    >
      <div className={`flex items-center space-x-1  text-xs text-[#189057]`}>
        <FontAwesomeIcon icon={faCheck} />
        <p>Success</p>
      </div>
      <p className="text-white font-semibold">{title}</p>
      <p className="text-white text-sm font-light">{message}</p>
    </div>
  );
}

function InformationNotification({
  title,
  message,
  onClose,
}: {
  title: string;
  message: string;
  onClose: () => void;
}) {
  useEffect(() => {
    const timeId = setTimeout(() => {
      onClose();
    }, 2000);

    return () => {
      clearTimeout(timeId);
    };
  }, []);

  return (
    <div
      className={`shadow-lg bg-tic-taps-grey shadow-[#339ce2] w-full rounded-2xl h-24 flex flex-col p-2 border-hairline border-[#339ce2]`}
    >
      <div className={`flex items-center space-x-1  text-xs text-[#339ce2]`}>
        <FontAwesomeIcon icon={faCircleInfo} />
        <p>Info</p>
      </div>
      <p className="text-white font-semibold">{title}</p>
      <p className="text-white text-sm font-light">{message}</p>
    </div>
  );
}

function ErrorNotification({
  title,
  message,
  onClose,
}: {
  title: string;
  message: string;
  onClose: () => void;
}) {
  useEffect(() => {
    const timeId = setTimeout(() => {
      onClose();
    }, 2000);

    return () => {
      clearTimeout(timeId);
    };
  }, []);

  return (
    <div
      className={`shadow-lg bg-tic-taps-grey shadow-[#FF5E00] w-full rounded-2xl h-24 flex flex-col p-2 border-hairline border-[#FF5E00]`}
    >
      <div className={`flex items-center space-x-1  text-xs text-[#FF5E00]`}>
        <FontAwesomeIcon icon={faTriangleExclamation} />
        <p>Error</p>
      </div>
      <p className="text-white font-semibold">{title}</p>
      <p className="text-white text-sm font-light">{message}</p>
    </div>
  );
}

function NotificationCard({
  notification,
  onClose,
}: {
  notification: Notification;
  onClose: () => void;
}) {
  const { title, message, type } = notification;
  switch (type) {
    case NotificationType.SUCCESS:
      return (
        <div key={notification.id}>
          <SuccessNotification
            title={title}
            message={message}
            onClose={onClose}
          />
        </div>
      );
    case NotificationType.ERROR:
      return (
        <div key={notification.id}>
          <ErrorNotification
            title={title}
            message={message}
            onClose={onClose}
          />
        </div>
      );
    case NotificationType.WARNING:
      return (
        <div key={notification.id}>
          <WarningNotification
            title={title}
            message={message}
            onClose={onClose}
          />
        </div>
      );
    case NotificationType.INFO:
      return (
        <div key={notification.id}>
          <InformationNotification
            title={title}
            message={message}
            onClose={onClose}
          />
        </div>
      );
  }
}

export const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  function logErrorCode(errror: TTError) {
    //@ts-ignore
    const error = errors[errror.code];
    logError(error.title, error.description);
  }

  function logError(title: string, message: string) {
    setNotifications((prev) => [
      ...prev.splice(0, 2),
      { id: uuid(), title, message, type: NotificationType.ERROR },
    ]);
  }

  function logWarning(title: string, message: string) {
    setNotifications((prev) => [
      ...prev.splice(0, 2),
      { id: uuid(), title, message, type: NotificationType.WARNING },
    ]);
  }

  function removeNotification(id: string) {
    setNotifications(notifications.filter((n) => n.id !== id));
  }

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        logError,
        logErrorCode,
        logWarning,
        removeNotification,
      }}
    >
      {children}
      <div className="absolute top-0 w-full h-screen pointer-events-none z-50">
        <div className="absolute bottom-0 w-full p-4 space-y-2">
          {notifications.map((notification: Notification) => {
            return (
              <NotificationCard
                onClose={() => removeNotification(notification.id)}
                notification={notification}
              />
            );
          })}
        </div>
      </div>
    </NotificationContext.Provider>
  );
};

export const useNotifications = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error("useLoading must be used within a LoadingProvider");
  }
  return context;
};
