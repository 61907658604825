import React, { useEffect, useState } from "react";
import {
  Chat,
  Channel,
  Window,
  ChannelHeader,
  MessageList,
  MessageInput,
  Thread,
  useChatContext,
} from "stream-chat-react";
import { useParams } from "react-router-dom";
import { IoChevronBack } from "react-icons/io5";
import useTTRouter from "src/router/hooks/useTTRouter";
import "stream-chat-react/dist/css/v2/index.css";
import "src/css/partyChat.css";

export function ChatPage() {

  const [channel, setChannel] = useState();
  const { navigateBack } = useTTRouter();
  const { client } = useChatContext();
  const { chatId } = useParams();

  useEffect(() => {
    if (client && chatId) {
      createChannel();
    }
  }, []);

  const createChannel = async () => {
    const channel = client.channel("messaging", chatId);
    if (channel) {
      channel.watch().then(() => {
        //Hate TS_Ignore but types for getStream is fucked
        // @ts-ignore
        setChannel(channel);
      });
    }
  };

  return (
    <>
      {client !== null && channel !== null && (
        <div className="h-screen pt-12 pb-8">
          <Chat client={client}>
            <Channel channel={channel}>
              <Window>
                <div className="flex flex-row items-center ">
                  <button className="pl-3 pr-2" onClick={() => navigateBack()}>
                    <IoChevronBack size={24} />
                  </button>
                  <ChannelHeader />
                </div>
                <MessageList />
                <MessageInput />
              </Window>
              <Thread />
            </Channel>
          </Chat>
        </div>
      )}
    </>
  );
}
