
import clsx from 'clsx'
import frame from '../staticComponents/images/phone_frame_crop.png';

export function PhoneFrame({
  className,
  children,
  priority = false,
  ...props
}: React.ComponentPropsWithoutRef<'div'> & { priority?: boolean }) {
  return (
    <div
      className={clsx('relative aspect-[366/729]', className)}
      {...props}
    >
      <div className="absolute inset-0 flex items-center rounded-3xl justify-center overflow-hidden shadow-2xl">
        <div className="relative h-[95%] w-[88%] overflow-hidden">
          {children}
        </div>
      </div>
      <img
        src={frame}
        alt=""
        className="pointer-events-none absolute inset-0 h-full w-full"
      />
    </div>
  );
}