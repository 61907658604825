import BubblesBackground from './BubblesBackground'
import { Container } from './Container';
import { Footer } from './Footer';

const faqs = [
  [
    {
      question: 'How do I know the games are reliable?',
      answer:
        'We prioritize quality and reliability in our games because your success is our success. Our community of players has consistently shared positive experiences that reflect the effectiveness of our insights. Just give it a try—you won’t be disappointed!',
    },
    {
      question: 'Can I really make money through gaming?',
      answer:
        'Absolutely! Tictaps combines gaming with cryptocurrency, allowing you to earn real rewards for your gameplay. The more you play and engage with the community, the more opportunities you have to boost your earnings.',
    },
    {
      question: 'Is there a risk involved with using Tictaps?',
      answer:
        'As with any investment or gaming platform, there are risks. However, we provide valuable tips and insights to help you make informed decisions. Always play responsibly and only invest what you can afford to lose.',
    },
  ],
  [
    {
      question: 'Do I need prior gaming experience to get started?',
      answer:
        'Not at all! Tictaps is designed for gamers of all levels. Whether you’re a newbie or a seasoned player, our platform offers user-friendly tools and resources to help you excel and make the most of your gaming experience.',
    },
    {
      question: 'How old do I need to be to use Tictaps?',
      answer:
        'Users must meet the minimum age requirements to trade and participate in gaming in their respective countries. However, our platform is suitable for players of all ages, so if you’re passionate about gaming, Tictaps is for you!',
    },
    {
      question: 'Can I play Tictaps on multiple devices?',
      answer:
        'Yes! Tictaps is accessible on various devices, including smartphones and tablets. Enjoy gaming on the go and never miss a chance to earn rewards, no matter where you are!',
    },
  ],
  [
    {
      question: 'How do I withdraw my earnings from Tictaps?',
      answer:
        'Withdrawing your earnings is easy! Simply navigate to the withdrawal section in the app, and follow the prompts. We aim to process requests quickly, ensuring you have access to your funds when you need them.',
    },
    {
      question: 'What if I encounter issues while using the app?',
      answer:
        'If you run into any problems, our support team is here to help! Reach out through the app or our website, and we’ll assist you as quickly as possible to ensure a smooth gaming experience.',
    },
    {
      question: 'How can I become a part of the Tictaps community?',
      answer:
        'Join our vibrant community by signing up for Tictaps! Engage with other players, share tips, and participate in discussions. The more you interact, the more you’ll benefit from our collective knowledge and experiences.',
    },
  ],
];

export function Faqs() {
  return (
    <>
    <section
    id="faqs"
    aria-labelledby="faqs-title"
    className="pb-16 pt-20 sm:pb-24 sm:pt-32 relative bg-zinc-900 overflow-hidden"
  >
    {/* <div className="absolute md:-top-40  left-1/2 transform -translate-x-1/2 w-full opacity-75 scale-x-100 blur-3xl z-0">
      <BubblesBackground />
    </div> */}
  
    <div className='relative z-10'> 
      <Container>
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2
            id="faqs-title"
            className="text-3xl font-medium tracking-tight text-white"
          >
            Frequently asked questions
          </h2>
          <p className="mt-2 text-lg text-gray-200">
            If you have anything else you want to ask,{' '}
            <a
              href="mailto:info@example.com"
              className="text-white underline"
            >
              reach out to us
            </a>
            .
          </p>
        </div>
        <ul
          role="list"
          className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 sm:mt-20 lg:max-w-none lg:grid-cols-3"
        >
          {faqs.map((column, columnIndex) => (
            <li key={columnIndex}>
              <ul role="list" className="space-y-10">
                {column.map((faq, faqIndex) => (
                  <li key={faqIndex}>
                    <h3 className="text-lg font-semibold leading-6 text-gray-100">
                      {faq.question}
                    </h3>
                    <p className="mt-4 text-sm text-gray-200">{faq.answer}</p>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </Container>
    </div>
    {/* <section className='pt-20'>
     <Footer />
     </section> */}
  </section>
  </>
  )
}
