import { ChevronDownIcon, PencilSquareIcon } from "@heroicons/react/24/solid";
import React, { useEffect, useRef, useState } from "react";
import { Sheet } from "react-modal-sheet";
import { useAccount } from "src/providers/accountProvider";
import GradientContainer from "src/staticComponents/GradientContainer";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
interface AccountSheetProps {
  open: boolean;
  onClose: () => void;
}

const AccountSheet: React.FC<AccountSheetProps> = ({ open, onClose }) => {
  const { account, patchProfile, refreshAccount } = useAccount();
  
  const [username, setUsername] = useState(account?.gamerTag || "");
  const [profileImage, setProfileImage] = useState(account?.profileImage || "");
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);

  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleFocus = () => setIsKeyboardVisible(true);
    const handleBlur = () => setIsKeyboardVisible(false);

    const inputs = document.querySelectorAll("input, textarea");
    inputs.forEach((input) => {
      input.addEventListener("focus", handleFocus);
      input.addEventListener("blur", handleBlur);
    });

    return () => {
      inputs.forEach((input) => {
        input.removeEventListener("focus", handleFocus);
        input.removeEventListener("blur", handleBlur);
      });
    };
  }, []);

  useEffect(() => {
    if (isKeyboardVisible && contentRef.current) {
      contentRef.current.style.paddingBottom = "300px";
    } else if (contentRef.current) {
      contentRef.current.style.paddingBottom = "0px";
    }
  }, [isKeyboardVisible]);

  const handleChangeProfilePicture = async () => {
    try {
      const image = await Camera.getPhoto({
        resultType: CameraResultType.Base64, 
        source: CameraSource.Prompt, 
        quality: 90,
      });

      if (image.base64String) {
        const base64Image = `data:image/jpeg;base64,${image.base64String}`;
        setProfileImage(base64Image);
      }
    } catch (error) {
      console.error("Failed to select image:", error);
    }
  };

  const handleSave = async () => {
    try {
      await patchProfile({
        gamer_tag: username,
        nickname: username,
        profile_image: profileImage,
      });
      refreshAccount(); 
      onClose();
    } catch (error) {
      console.error("Failed to update profile:", error);
    }
  };
  
  return (
    <Sheet isOpen={open} onClose={onClose}>
      <Sheet.Container className="bg-custom-zinc max-h-[400px]">
        <Sheet.Header className="text-white text-center font-bold text-xl p-2 z-10 flex items-center justify-center relative">
          <span className="flex-1">Account Settings</span>
          <ChevronDownIcon className="h-5 w-5 absolute right-2" />
        </Sheet.Header>
        <Sheet.Content className="relative flex flex-col items-center p-4 overflow-hidden z-10 flex-grow">
          <div className="flex flex-col items-center w-full space-y-6">
            <div className="relative flex flex-col items-center space-y-3">
              <div className="w-24 h-24 rounded-full bg-zinc-900 overflow-hidden border-2 border-cyan-500 shadow-md">
                <img
                  src={profileImage || "/images/default-profile.png"}
                  alt="Profile"
                  className="w-full h-full object-cover"
                />
              </div>
              <button
                className="text-sm text-white hover:underline text-nowrap flex items-center"
                onClick={handleChangeProfilePicture}
              >
                <PencilSquareIcon className="w-4 h-4 my-1 mx-1" /> Change Profile Picture
              </button>
            </div>
            <div className="flex flex-col w-full space-y-4">
              <div>
                <label className="block text-sm text-gray-400">Username</label>
                <input
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="w-full px-4 py-3 text-white bg-gradient-to-r from-tic-taps-grey to-zinc-900 border-hairline border-zinc-700 rounded-2xl focus:outline-none focus:ring-2 focus:ring-cyan-500"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center mt-8 mb-6 w-full">
            <GradientContainer styles="rounded-2xl w-full text-center shadow-glow">
              <button
                onClick={handleSave}
                className="text-white hover:text-purple-200 font-bold py-3 px-6"
              >
                Save changes
              </button>
            </GradientContainer>
          </div>
        </Sheet.Content>
      </Sheet.Container>
    </Sheet>
  );
};

export default AccountSheet;