import { Participant } from "../../../types";
import { Get } from "../../fetch.wrapper";
import { routes } from "../../routes";

export default async function GetLeaderboard(
  partyId: string
): Promise<Participant[]> {
  const data = await Get(routes.partyLeaderboard(partyId));
  const res = data.map(
    (e: any) =>
      ({
        profile: {
          id: e.user_id,
          username: e.username,
          gamertag: e.gamertag,
          profileImage: e.profile_picture,
          email: e.email,
          xp: e.xp,
        },
        buyIn: e.buy_in_amount,
        highSchore: e.score,
      } as Participant)
  ) as Participant[];
  return res;
}
