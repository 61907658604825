import React, { useId } from 'react';

export function CircleBackground({
  ...props
}: React.ComponentPropsWithoutRef<'svg'>) {
  let id = useId();

  return (
    <svg
      viewBox="0 0 558 558"
      width="558"
      height="558"
      fill="none"
      aria-hidden="true"
      {...props}
    >
      <defs>
        <linearGradient
          id={id}
          x1="0%"
          y1="0%"
          x2="100%"
          y2="100%"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#06b6d4" /> 
          <stop offset="0.5" stopColor="#2563eb" /> 
          <stop offset="1" stopColor="#a855f7" /> 
        </linearGradient>
      </defs>
      <circle
        cx="279"
        cy="279"
        r="278"
        stroke={`url(#${id})`} 
        strokeWidth="2"
        fill="none"
      />
      <path
        opacity=".2"
        d="M1 279C1 125.465 125.465 1 279 1s278 124.465 278 278-124.465 278-278 278S1 432.535 1 279Z"
        stroke="#000"
      />
    </svg>
  );
}

export function CircleBackgroundGray({
  color = "#111827",
  ...props
}: React.ComponentPropsWithoutRef<'svg'>) {
  let id = useId();

  return (
    <svg
      viewBox="0 0 558 558"
      width="558"
      height="558"
      fill="none"
      aria-hidden="true"
      {...props}
    >
      <defs>
        <linearGradient
          id={id}
          x1="0%"
          y1="0%"
          x2="100%"
          y2="100%"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#06b6d4" /> 
          <stop offset="0.5" stopColor="#2563eb" /> 
          <stop offset="1" stopColor="#a855f7" /> 
        </linearGradient>
      </defs>
      <circle
        cx="279"
        cy="279"
        r="278"
        stroke={`url(#${id})`} 
        strokeWidth="2"
        fill={color}
      />
      <path
        opacity=".2"
        d="M1 279C1 125.465 125.465 1 279 1s278 124.465 278 278-124.465 278-278 278S1 432.535 1 279Z"
        stroke="#000"
      />
    </svg>
  );
}


