import { useNavigate, useParams } from "react-router-dom";
import { ProfileProvider, useProfile } from "src/providers/profileProvider";
import PartyHistoryCard from "src/components/PartyHistoryCard";
import { FollowersList } from "src/components/FollowersList";
import { UserProfileComponent } from "src/components/UserProfileComponent";
import { useImageBackground } from "src/providers/layoutProvider";
import { PartyHistoryEntry, Profile } from "src/types";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { FullScreenLoadingOverlay } from "src/components/LoadingOverlay";
import PullToRefresh from "react-simple-pull-to-refresh";
import { GradientBorder } from "src/staticComponents/GradientContainer";
import { useEffect, useState } from "react";
import useFollowing from "src/hooks/useFollowing";
import { useAccount } from "src/providers/accountProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faHeartBroken } from "@fortawesome/free-solid-svg-icons";
import { UnfollowModal } from "src/components/Modals";

export function ProfilePage() {
  const { userId } = useParams<{ userId: string }>();

  if (!userId) {
    return (
      <p className="text-cyan-500 font-light text-2xl">
        {" "}
        User not found, contact support.{" "}
      </p>
    );
  }

  return (
    <ProfileProvider userId={userId}>
      <FollowerProfileComponent />
    </ProfileProvider>
  );
}

function groupByDate(data: PartyHistoryEntry[]) {
  const groupedData = data.reduce<Record<string, PartyHistoryEntry[]>>(
    (accumulator, current) => {
      const date = current.date;
      if (!accumulator[date]) {
        accumulator[date] = [];
      }
      accumulator[date].push(current);
      return accumulator;
    },
    {}
  );

  return Object.keys(groupedData)
    .sort()
    .map((date) => ({
      date,
      entries: groupedData[date].sort((a, b) => b.date.localeCompare(a.date)),
    }));
}

function FollowerProfileComponent() {
  const profileContext = useProfile();
  const navigate = useNavigate();

  if (!profileContext) {
    throw new Error("useProfile must be used within a ProfileProvider");
  }

  const [isUnFollowModal, setIsUnFollowModal] = useState(false);
  const { follow, unfollow } = useFollowing();
  const { following } = useAccount() || {};
  const [isFollowed, setIsFollowed] = useState(false);
  const { followers, profile, history, isProfileLoading, refreshProfile } =
    profileContext;

  useEffect(() => {
    if (profile?.id && following) {
      const followed = following.some((user: any) => user.id === profile.id);
      setIsFollowed(followed);
    }
  }, [profile?.id, following]);

  const toggleUnFollowModal = (follower: Profile) => {
    setIsUnFollowModal(true);
    setIsFollowed(false);
  };

  const handleUnfollowFollowConfirm = () => {
    if (profile) {
      setIsUnFollowModal(false);
      unfollow(profile.id);
      setIsFollowed(false);
    }
  };

  const handleFollow = () => {
    if (profile) {
      follow(profile.id);
      setIsFollowed(true);
    }
  };

  useImageBackground(profile?.profileImage);
  const handleBackClick = () => {
    navigate(-1);
  };

  if (isProfileLoading) {
    return (
      <FullScreenLoadingOverlay
        display={isProfileLoading}
        description={"Loading profile..."}
      />
    );
  } else if (!profile) {
    return (
      <p className="text-cyan-500 font-light text-2xl">
        {" "}
        User not found, contact support.{" "}
      </p>
    );
  }

  const groupedHistory = groupByDate(
    (history || []).flatMap((profileHistory) => profileHistory.parties)
  );

  return (
    <div className="h-full overflow-y-auto">
      <FullScreenLoadingOverlay
        display={isProfileLoading}
        description={"Loading profile..."}
      />
      <PullToRefresh onRefresh={async () => refreshProfile()}>
        <>
          <div className="flex flex-col justify-center space-y-4 relative z-[30]">
            <button
              onClick={handleBackClick}
              className="absolute z-[60] top-4 px-2 left-4 mt-1 bg-transparent text-white rounded-full hover:bg-gray-600"
            >
              <ChevronLeftIcon className="h-8 w-8" />
            </button>
            <UserProfileComponent
              isProfileLoading={isProfileLoading}
              profile={{
                profileImage: profile.profileImage,
                username: profile.username || "Unknown",
                gamertag: profile.gamertag,
                id: profile.id,
                email: profile.email || "No email provided",
                xp: profile.xp,
              }}
              winRatio={history?.[0].winRatio || 0}
            />
            <div className="absolute z-[80] top-1 right-8 text-white rounded-xl">
              {isFollowed ? (
                <GradientBorder
                  from="#FF5E00"
                  to="#FFAA00"
                  via={["#FFAA00"]}
                  rounded="rounded-lg"
                >
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleUnFollowModal(profile.id);
                    }}
                    className="flex items-center justify-center bg-transparent text-orange-500 p-2 rounded-xl text-sm font-semibold shadow-md"
                  >
                    <FontAwesomeIcon icon={faHeartBroken} />
                  </button>
                </GradientBorder>
              ) : (
                <GradientBorder rounded="rounded-lg">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleFollow();
                    }}
                    className="flex items-center justify-center bg-transparent text-cyan-500 p-2 rounded-xl text-sm font-semibold shadow-md"
                  >
                    <FontAwesomeIcon icon={faHeart} />
                  </button>
                </GradientBorder>
              )}
            </div>
          </div>
          <div className="flex flex-col gap-y-2 relative z-[30]">
            <FollowersList
              isFollowersLoading={isProfileLoading}
              followers={followers ?? []}
              title="Followers"
            />
          </div>
          <div className="flex flex-col gap-y-2 relative z-[10] mb-60 pt-2">
            {groupedHistory.length === 0 ? (
              <div className="flex justify-center items-center h-full pt-16">
                <p className="text-lg text-white font-light">
                  No games played yet 😔
                </p>
              </div>
            ) : (
              groupedHistory.slice(0, 1).map(({ date, entries }) => (
                <div key={date} className="flex flex-col space-y-1 px-2">
                  <h3 className="text-md text-white font-semibold ">
                    Game History
                  </h3>
                  <div className="flex flex-row w-full justify-between backdrop-blur rounded-lg">
                    <div className="flex overflow-x-scroll space-x-2 pt-2 px-2 pb-2">
                      {entries.map((party) => (
                        <PartyHistoryCard
                          key={party.id}
                          onClick={() => {}}
                          game={party.game}
                          party={party}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
          {isUnFollowModal && (
            <UnfollowModal
              isOpen={isUnFollowModal}
              followerName={profile.gamertag}
              onRequestClose={() => setIsUnFollowModal(false)}
              unfollowConfirm={handleUnfollowFollowConfirm}
            />
          )}
        </>
      </PullToRefresh>
    </div>
  );
}
