"use client";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useInstallPWA } from "../hooks/useInstall";
import { useEffect, useState } from "react";
import { GradientBorder } from "../components/ui/GradientContainer";

const baseStyles = {
  solid:
    "inline-flex justify-center rounded-lg py-2 px-3 text-sm font-semibold outline-2 outline-offset-2 transition-colors",
  outline:
    "inline-flex justify-center rounded-lg border py-[calc(theme(spacing.2)-1px)] px-[calc(theme(spacing.3)-1px)] text-sm outline-2 outline-offset-2 transition-colors",
};

const variantStyles = {
  solid: {
    cyan: "relative overflow-hidden bg-cyan-500 text-white before:absolute before:inset-0 active:before:bg-transparent hover:before:bg-white/10 active:bg-cyan-600 active:text-white/80 before:transition-colors",
    white:
      "bg-white text-cyan-900 hover:bg-white/90 active:bg-white/90 active:text-cyan-900/70",
    gray: "bg-gray-800 text-white hover:bg-gray-900 active:bg-gray-800 active:text-white/80",
  },
  outline: {
    gray: "border-gray-300 text-gray-700 hover:border-gray-400 active:bg-gray-100 active:text-gray-700/80",
  },
} as const;

type SolidColors = keyof typeof variantStyles.solid;
type OutlineColors = keyof typeof variantStyles.outline;

type ButtonProps = (
  | {
      variant?: "solid";
      color?: SolidColors;
    }
  | {
      variant: "outline";
      color?: OutlineColors;
    }
) &
  (
    | (Omit<React.ComponentPropsWithoutRef<typeof Link>, "color"> & {
        href: string;
      })
    | (Omit<React.ComponentPropsWithoutRef<"button">, "color"> & {
        href?: undefined;
      })
  );

export function Button({ className, ...props }: ButtonProps) {
  const variant = props.variant ?? "solid";
  const color = props.color ?? "gray";

  className = clsx(
    baseStyles[variant],
    variant === "outline"
      ? variantStyles.outline[color as OutlineColors]
      : variantStyles.solid[color as SolidColors],
    className
  );

  return props.href ? (
    <Link
      className={className}
      {...(props as React.ComponentPropsWithoutRef<typeof Link>)}
    />
  ) : (
    <button
      className={className}
      {...(props as React.ComponentPropsWithoutRef<"button">)}
    />
  );
}

export function InstallButton() {
  const { install } = useInstallPWA();
  const [isIOS, setIsIOS] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor;
    const iOS = /iPad|iPhone|iPod/.test(userAgent);
    setIsIOS(iOS);
  }, []);

  return (
    <>
    {!isIOS && (
        <GradientBorder padding="p-0.5" rounded="rounded-lg">
          <button
            onClick={()=>install()}
            className="px-5 py-2 rounded-lg transition-colors bg-tic-taps-grey text-white hover:bg-gray-900 hover:shadow-glow"
          >
            Download
          </button>
        </GradientBorder>
      )}
  </>
  );
}
