export default function GradientContainer({
  children,
  styles,
}: {
  children: React.ReactNode;
  styles?: string;
}) {
  return (
    <div
      className={`bg-gradient-to-r from-cyan-500 via-blue-600 to-purple-500 ${styles}`}
    >
      {children}
    </div>
  );
}

export function GradientBorder({
  children,
  from,
  via,
  to,
  padding = "p-hairline",
  rounded = "rounded-xl",
  disabled,
}: {
  children: React.ReactNode;
  from?: string;
  via?: string[];
  to?: string;
  padding?: string;
  rounded?: string;
  disabled?: boolean;
}) {
  const fromClassnames = !disabled
    ? from
      ? `from-[${from}]`
      : "from-cyan-500"
    : "";
  const toClassnames = !disabled ? (to ? `to-[${to}]` : "to-purple-500") : "";
  const viaClassnames = !disabled
    ? via?.map((v) => `via-[${v}]`).join(" ") ?? "via-blue-600"
    : "";

  return (
    <div
      className={`h-full  ${
        disabled ? "bg-gray-600" : "bg-gradient-to-r"
      } ${padding} ${fromClassnames} ${toClassnames} ${viaClassnames} ${rounded}`}
    >
      <div
        className={`bg-tic-taps-grey h-full w-full flex items-center ${rounded}`}
      >
        {children}
      </div>
    </div>
  );
}
