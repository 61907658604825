import { Sheet } from "react-modal-sheet";
import PartyCard from "./PartyCard";
import SeeAll from "./SeeAll";
import { useState } from "react";
import { Party } from "../types";
import { LoadingSpinner } from "./LoadingOverlay";
import { Transition } from "@headlessui/react";
import { PartyStatus } from "../api/party/getParty";

export function PartiesHorizontalList({
  title,
  parties,
  onPartyClick,
  isLoading,
  fallback,
  firstElement,
}: Readonly<{
  title: string;
  parties: Party[];
  onPartyClick: (party: Party) => void;
  isLoading?: boolean;
  fallback?: () => React.ReactNode;
  firstElement?: () => React.ReactNode;
}>) {
  const [open, setOpen] = useState(false);
  const [finishedLoading, setFinishedLoading] = useState<boolean>(true);

  return (
    <>
      <div className="gap-y-1">
        <div className="px-2">
          <div className="relative w-full h-full">
            <div className="absolute w-full h-full backdrop-blur-xl rounded-xl"></div>
            <div className="flex flex-row relative items-center justify-between bg-gradient-to-br to-white/10 via-[#34414d]/10 from-[#34414d]/10 border-hairline border-[#34414d]/30 rounded-xl py-2 px-4">
              <p className="text-white font-semibold text-sm">{title}</p>
              <SeeAll onClick={() => setOpen(!open)} />
            </div>
          </div>
        </div>

        <Transition
          show={isLoading}
          afterLeave={() => setFinishedLoading(true)}
        >
          <div className="h-44 w-full flex justify-center items-center transition duration-150 ease-in data-[closed]:opacity-0">
            <LoadingSpinner />
          </div>
        </Transition>

        <Transition show={finishedLoading && !isLoading}>
          <div
            style={{ WebkitOverflowScrolling: "touch" }}
            className="flex overflow-x-scroll space-x-2 py-2 px-2"
          >
            {parties.length !== 0 && firstElement ? firstElement() : <></>}
            {parties.length === 0 && fallback ? fallback() : <></>}
            {parties
              .filter((p) => p.status === PartyStatus.ACTIVE)
              .map((party) => (
                <PartyCard
                  onClick={() => onPartyClick(party)}
                  key={party.id}
                  game={party.game}
                  party={party}
                />
              ))}
          </div>
        </Transition>
      </div>

      <Sheet isOpen={open} onClose={() => setOpen(false)}>
        <Sheet.Container>
          <Sheet.Header className="bg-tic-taps-grey text-white font-bold text-xl p-2 border-b-2 border-tic-taps-grey">
            {" "}
            {title}{" "}
          </Sheet.Header>
          <Sheet.Content className="bg-tic-taps-grey flex items-center">
            <div className="grid grid-cols-2 gap-x-2 gap-y-2 px-2">
              {parties.length === 0 && fallback ? fallback() : <></>}
              {parties
                .filter((p) => p.status === PartyStatus.ACTIVE)
                .map((party) => (
                  <PartyCard
                    onClick={() => onPartyClick(party)}
                    key={party.id}
                    game={party.game}
                    party={party}
                  />
                ))}
            </div>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
    </>
  );
}
