import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useTTRouter from "../router/hooks/useTTRouter";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

export default function SecondaryHeader({ text }: { text: string }) {
  const { navigateBack } = useTTRouter();
  return (
    <div className="flex w-full justify-center p-2 sticky top-0 backdrop-blur-2xl bg-opacity-40 bg-transparent">
      <button
        onClick={()=>navigateBack()}
        className="w-fit h-fit absolute top-0 left-0 p-2"
      >
        <FontAwesomeIcon icon={faChevronLeft} size="2x" color="#ffffff" />
      </button>
      <p className="text-white text-2xl font-semibold">{text}</p>
    </div>
  );
}
